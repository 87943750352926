%summary {
  @include responsive-font(large);
  flex-direction: column;
  align-items: stretch;
  background: $primary-color;
  border-radius: $panel-radius;
  display: flex;
  overflow: hidden;
  white-space: nowrap;

  @include at-tablet-width {
    flex-direction: row;
  }
}

%summary-item {
  align-items: flex-start;
  color: $page-color;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  min-width: 0; // fixes sizing bug
  padding: ($panel-spacing--tight * .7) $panel-spacing--tight;

  @include at-tablet-width {
    padding: ($panel-spacing * .7) $panel-spacing;
  }
}

.Summary {
  @extend %summary;
}

.Summary__item--primary {
  @extend %summary-item;
}

.Summary__item--primary-right {
  @extend %summary-item;
  @include at-tablet-width {
    align-items: flex-end;
  }
}

.Summary__item--secondary {
  @extend %summary-item;
  opacity: .5;
}

.Summary__item--alternate {
  @extend %summary-item;
  background: $primary-color--xxlight;
  color: $primary-color;
}

.Summary__item-label {
  @include responsive-font(normal);
  font-weight: $font-normal;
  text-transform: uppercase;
  opacity: .7;
}

.Summary__item-data {
  @include responsive-font(xlarge);
  font-weight: $font-bold;
}

.Summary__item-data--large {
  @include responsive-font(xxlarge);
  font-weight: $font-bold;
}

.Summary__item-info {
  @include responsive-font(xlarge);
  font-weight: $font-light;
  opacity: .6;
}
