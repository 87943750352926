
// MOVIE ROWS (MOBILE MOVIE ENTRIES)

$movie-row-height: 3.625rem;
$movie-row-icon-size: 1.3rem;

%MovieRow {
  align-items: stretch;
  cursor: default;
  display: flex;
  flex-direction: row;
  line-height: 1.1;
  margin-bottom: 1px;
  min-height: $movie-row-height;
  width: 100%;
}

@mixin movie-row-cap($bg, $color) {
  background-color: $bg;

  .MovieRow__cap {
    color: $color;
  }

  .MovieCard__enter-prediction {
    border-color: $bg;
  }
}

@mixin movie-row-content($bg, $color) {
  color: $color;

  .MovieRow__pick,
  .MovieRow__icon {
    background-color: $bg;
  }
}

@mixin movie-row-editable($bg) {
  cursor: pointer;

  .MovieRow__pick {
    background-color: $page-color;
    border-bottom: 1px solid $bg;
    border-top: 1px solid $bg;
    &:last-child {
      border-right: 1px solid $bg;
    }
  }
  .MovieRow__icon {
    background-color: $page-color;
    border: 1px solid $bg;
    border-left: 0;
  }
}

.MovieRow {
  @extend %MovieRow;
  @include movie-row-cap($primary-color, $page-color);
  @include movie-row-content($primary-color--xxxlight, $primary-color);

  &.is-empty {
    @include movie-row-cap($primary-color--xxlight, $page-color);
    cursor: pointer;
  }

  &.is-incomplete {
    @include movie-row-cap($accent-color, $page-color);
    @include movie-row-content($accent-color, $page-color);
  }

  &.is-editable {
    @include movie-row-editable($primary-color);
  }

  &.is-locked {
    @include movie-row-content($primary-color--xxlight, $primary-color);
  }

  &.is-final,
  &.is-void {
    @include movie-row-content($primary-color, $page-color);
  }
}

// CHEAP SEATS

.MovieRow--cheap-seat {
  @extend %MovieRow;
  @include movie-row-cap($alternate-color--xdark, $page-color);
  @include movie-row-content($alternate-color--xxlight, $alternate-color--xdark);

  &.is-empty {
    @include movie-row-cap($alternate-color, $page-color);
    cursor: pointer;
  }

  &.is-incomplete {
    @include movie-row-cap($accent-color, $page-color);
    @include movie-row-content($accent-color, $page-color);
  }

  &.is-editable {
    @include movie-row-editable($alternate-color--xdark);
  }

  &.is-locked {
    @include movie-row-content($alternate-color--xlight, $alternate-color--xdark);
  }

  &.is-final,
  &.is-void {
    @include movie-row-content($alternate-color--xdark, $page-color);
  }

  .MovieRow__pick-message {
    color: $alternate-color--xdark;
  }
}

// DATE ALERT

.MovieRow--date-alert {
  @extend %MovieRow;
  @include movie-row-cap($highlight-color, $page-color);
  @include movie-row-content($highlight-color--light, $alternate-color--xdark);
}

// COMPONENTS

.MovieRow__cap {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: $movie-row-height;

  .Icon {
    width: 100%;
    height: 1.25em;
    margin-bottom: 0;
  }
}

.MovieRow__rank {
  font-size: 1.75rem;
  line-height: .9;
}

.MovieRow__rank-actual {
  font-size: .75rem;
}

.MovieRow__pick {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 300;
  flex: 1 1 100%;
  padding: .25rem .625rem;
  position: relative;
  min-width: 0; // hack to fix ellipsis bug

  &:not(:last-child) {
    padding-right: 0;
  }
}

.MovieRow__pick-link {
  color: $accent-color;
  text-align: center;
}

.MovieRow__pick-message {
  color: $primary-color;
  text-align: center;
}

.MovieRow__movie-title {
  @include ellipsis;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.MovieRow__release {
  white-space: nowrap;
  overflow: hidden;
  font-size: 1rem;

  span {
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.MovieRow__earnings {
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.5rem;
  font-weight: 300;
}

.MovieRow__earnings-value {
  position: relative;

  .is-void & {
    color: rgba(white, .5);

    &::after {
      background: white;
      content: '';
      display: block;
      height: 2px;
      left: -3px;
      position: absolute;
      right: -3px;
      top: 50%;
    }
  }
}

.MovieRow__icon {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0 .625rem 0 .5rem;
  position: relative;

  // hack to fix visual gap between info and icon elements
  &::before {
    content: '';
    background: inherit;
    position: absolute;
    top: 0;
    left: -1px;
    width: 2px;
    height: 100%;
  }
}
