%GraphicBanner {
  align-items: center;
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.GraphicBanner {
  @extend %GraphicBanner;

  &__image {
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__content {
    flex: 0 1 auto;
    max-width: $page-width;
    @include responsive(padding-left, 5rem, 60);
    @include responsive(padding-right, 5rem, 60);
    @include responsive(padding-top, 3rem, 60);
    @include responsive(padding-bottom, 3rem, 60);
    position: relative;
    text-align: center;

    .Link,
    .Link:hover {
      color: $highlight-color;
    }
  }

  &__subtitle {
    @include responsive-font(xxxlarge);
    @include responsive(margin-bottom, 2rem, 80);
    color: #fff;
    font-weight: $font-light;
    line-height: 1.2em;
  }

  &__text {
    color: $page-color;

    &:not(:first-child) { margin-top: .8em; }
    &:not(:last-child) { margin-bottom: .8em; }
  }
}

.GraphicBanner--large {
  @extend %GraphicBanner;
  min-height: 30rem;
  height: 70vw;
  max-height: 60vh;
  @include below-page-width {
    height: auto;
    min-height: 0;
    max-height: none;
  }
}
