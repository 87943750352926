%Notification {
  @include responsive-font(normal);
  border-radius: $notification-radius;
  padding: $notification-padding-small;
  position: relative;
  text-align: center;

  &.has-close {
    padding-left: $notification-close-padding;
    padding-right: $notification-close-padding;
  }

  @include at-tablet-width {
    &:not(.Notification--small) {
      padding: $notification-padding;
    }
  }
}

.Notification--primary {
  @extend %Notification;
  background-color: $alternate-color--xdark;
  color: $page-color;
}

.Notification--general {
  @extend %Notification;
  background-color: $alternate-color--xlight;
  color: $alternate-color--xdark;
}

.Notification--alert {
  @extend %Notification;
  background-color: $highlight-color--light;
  color: $base-color;
}

.Notification--success {
  @extend %Notification;
  background-color: $success-color;
  color: #fff;
}

.Notification__close {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: $notification-padding-small;

  @include at-tablet-width {
    &:not(.Notification--small) {
      padding: $notification-padding;
    }
  }
}

// Use with another notification class

.Notification--small {
  @include responsive-font(small);
}
