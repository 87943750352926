$movie-popup-spacing: $loose-spacing / 2;
$movie-popup-shadow: 0 0 10px rgba($base-color, .25);
$movie-popup-arrow-size: 26px;
$movie-popup-button-size: 38px; // matches tag height


@mixin movie-popup-action {
  flex: 1 1 50%;
  font-size: 18px;
  outline: none;
  padding: ($movie-popup-spacing * 1.5) ($movie-popup-spacing * 2);
  text-transform: uppercase;
  white-space: nowrap;
}

.MoviePopup {
  @include overflow-scrolling;
  background: white;
  max-height: 100%;
  max-width: $tablet-width;
  overflow: auto;
  padding: $movie-popup-spacing;
  position: relative;
  text-align: center;
  width: 100%;

  @include below-tablet-width {
    height: 100%;
    padding-bottom: 100px;
  }
  @include at-tablet-width {
    box-shadow: $movie-popup-shadow;
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    @include at-tablet-width {
      align-items: stretch;
    }

    > * {
      flex: 0 0 100%;
      position: relative;
      padding: $movie-popup-spacing;
    }
  }

  &__overlay {
    align-items: center;
    background: rgba(white, .9);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }

  &__tags {
    @include at-tablet-width {
      flex-basis: auto;
      order: -1;
    }
  }

  &__actions {

    @include below-tablet-width {
      background: $base-color--xxxlight;
      bottom: 0;
      display: flex;
      left: 0;
      padding: 0;
      position: fixed;
      text-align: center;
      width: 100%;
    }

    @include at-tablet-width {
      flex-basis: auto;
      justify-content: flex-end;
      order: -1;
      position: static;
    }
  }

  // Close
  &__action {
    @include below-tablet-width {
      @include movie-popup-action;
      color: $accent-color;
    }

    @include at-tablet-width {
      display: block;
      height: calc(1em + #{$movie-popup-spacing * 2});
      overflow: hidden;
      position: absolute;
      right: 0;
      text-indent: -999in;
      top: 0;
      width: calc(1em + #{$movie-popup-spacing * 2});

      &::after {
        align-items: center;
        bottom: 0;
        content: '✕';
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        text-indent: 0;
        top: 0;
      }
    }
  }

  // Select
  &__action--primary {
    @include below-tablet-width {
      @include movie-popup-action;
      background: $accent-color;
      color: white;
    }

    @include at-tablet-width {
      @include button($accent-color, $accent-color--dark);
      @include responsive-font(small);
      padding: ($panel-spacing / 2) 1em;
    }
  }

  &__title {
    @extend %page-title-small;
    margin: 0;
  }

  &__trailer {
    position: relative;

    @include below-tablet-width {
      order: -2;
      margin: (-$movie-popup-spacing) (-$movie-popup-spacing) ($movie-popup-spacing * 2);
      padding: 56.25% 0 0 !important;
      flex-basis: calc(100% + #{$movie-popup-spacing * 2});
    }

    @include at-tablet-width {
      flex-basis: 66.666%;
      padding-right: 0;

      + .MoviePopup__summary {
        flex-basis: 33.333%;
        padding-left: 1px;

        .MoviePopup__summary-items {
          flex-wrap: wrap;
        }
      }
    }

    &-video {
      background: $base-color--xxxlight;
      height: 100%;

      @include below-tablet-width {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &-image {
      background: $base-color--xxxlight;
      background-size: cover;
      height: 100%;
      width: 100%;
      padding: inherit;
      position: absolute;
      top: 0;
      left: 0;

      @include at-tablet-width {
        background-clip: content-box;
      }
    }
  }

  &__summary {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;

    &-poster {
      flex: 0 0 auto;

      img {
        display: block;
        width: 120px;

        @include at-tablet-width {
          display: none;
        }
      }
    }

    &-items {
      flex: 1 1 100%;
      display: flex;
      align-items: stretch;

      @include below-tablet-width {
        flex-wrap: wrap;
      }
    }

    &-item {
      background: $primary-color--xxxlight;
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      justify-content: center;
      line-height: 1;
      padding: $panel-spacing--tight;

      &:not(:first-child) {
        margin-top: 1px;
      }
    }
  }

  &__overview {
    @include at-tablet-width {
      flex-basis: 66.666%;
    }
  }

  &__credits {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;

    @include at-tablet-width {
      flex-basis: 33.333%;
    }
  }

  &__cast,
  &__crew {
    flex: 0 0 50%;
    padding: $movie-popup-spacing;

    @include at-tablet-width {
      flex: 0 0 100%;
    }
  }

  // POPUP EDITOR

  &__edit-title {
    @extend %page-title-small;
    font-weight: $font-bold;
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
    text-align: center;
  }

  &__edit-form {
    background-clip: content-box;
    display: block;
  }

  // RESULTS

  .MovieCard__earnings {
    background: $primary-color--xxlight;
    border-radius: 3px;
    color: $primary-color;
  }

  .MovieCard__secondary-result {

  }

}
