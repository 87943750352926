%tag {
  padding: ($panel-spacing / 2) $panel-spacing;
  background: $base-color--xxxlight;
  float: left;
}

.Tags {
  @include responsive-font(small);
  overflow: hidden;

  > *:first-child {
    border-radius: 5px 0 0 5px;
  }

  > *:last-child {
    border-radius: 0 5px 5px 0;
  }
}

.Tag {
  @extend %tag;

  &--primary {
    @extend %tag;
    color: white;
    text-transform: uppercase;
    background: $primary-color;
  }
}
