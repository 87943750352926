@mixin button($bg, $hover) {
  @include responsive-font(normal, 1);
  display: inline-block;
  padding: 1em;
  border-radius: .2em;
  background-color: $bg;
  transition: background-color 300ms;
  color: #fff;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  appearance: none;

  &:hover { background-color: $hover; }
}

%Button {
  @include button($primary-color, $primary-color--dark);
}

%Button--full {
  @extend %Button;
  width: 100%;
}

%Button--primary {
  @extend %Button;
  background-color: $accent-color;
  &:hover { background-color: $accent-color--dark; }
}

%Button--notification {
  text-transform: none;
  padding: $notification-padding;
  border-radius: $notification-radius;
}

%Button--large {
  @extend %Button;
  @include responsive-font(large, 1);
}

%Button--small {
  @extend %Button;
  @include responsive-font(small, 1);
}

// CLASSES

.Button {
  @extend %Button;
}

.Button--full {
  @extend %Button--full;
}

.Button--primary {
  @extend %Button--primary;
}

.Button--primary-full {
  @extend %Button--primary;
  @extend %Button--full;
}

.Button--primary-notification {
  @extend %Button--primary;
  @extend %Button--full;
  @extend %Button--notification;
}

.Button--large {
  @extend %Button--large;
}

.Button--large-full {
  @extend %Button--large;
  @extend %Button--full;
}

.Button--primary-large {
  @extend %Button--primary;
  @extend %Button--large;
}

.Button--primary-large-full {
  @extend %Button--primary;
  @extend %Button--large;
  @extend %Button--full;
}

.Button--small {
  @extend %Button--small;
}

.Button--small-full {
  @extend %Button--small;
  @extend %Button--full;
}

.Button--primary-small {
  @extend %Button--small;
  @extend %Button--primary;
}

.Button--primary-small-full {
  @extend %Button--small;
  @extend %Button--primary;
  @extend %Button--full;
}

.Button--small-secondary {
  @extend %Button--small;
  background: $page-color;
  border: 2px solid $primary-color;
  color: $primary-color;
  padding: calc(1em - 2px);

  &:hover {
    background-color: $alternate-color--xxxlight;
  }
}

// BUTTON BAR

.ButtonBar {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  > * {
    flex: 1 1 50%;

    &:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-left: 0;
    }

    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 0;
    }
  }
}
