$movie-poster-font-large: 1.2rem;
$movie-poster-font-medium: 1.0rem;

.MoviePoster {
  position: relative;
  display: block;
  border-top: 1px solid $page-color;
  border-bottom: 1px solid $page-color;
  padding-top: 150%;
  height: 0;
  overflow: hidden;
  width: 100%;
}

.MoviePoster__image {
  background-color: inherit;
  height: 100%;
  left: 0;

  position: absolute;
  top: 0;
  width: 100%;
  display: block;

  &::before,
  &::after {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    white-space: normal;
  }

  &::before {
    background-color: $base-color;
    color: $primary-color;
    content: attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt);
    display: block;
    filter: blur(.05em);
    font-size: 4vw;
    left: -20%;
    letter-spacing: .2em;
    line-height: .9;
    transform: scale(1.2) rotate(-30deg);
    text-transform: uppercase;
    width: 140%;
    word-break: break-all;

    @include at-desktop-width {
      font-size: $movie-poster-font-large;
    }
  }

  &::after {
    background: linear-gradient(to bottom right, rgba($primary-color, .2) -5%, rgba($base-color, 1) 105%);
    color: $base-color--xxxlight;
    content: attr(alt);
    font-family: $boc-base-font;
    font-size: $movie-poster-font-medium;
    font-weight: 700;
    left: 0;
    line-height: $movie-poster-font-medium;
    padding: .5em;
    right: 0;
  }
}
