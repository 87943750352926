$game-card--alert-icon-size: 1.2em;
$game-card--go-icon-size-small: 1rem;
$game-card--go-icon-size-large: 2.4rem;
$game-card--inner-radius: $panel-radius - $boc-border--thin;
$game-card-class-selector: '[class*="GameCard"]';

%GameCard {
  border-radius: $panel-radius;
  border: 1px solid $base-color--xlight;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    background: $page-color;
    padding: $panel-spacing;
    flex: 0 0 100%;

    &:first-child {
      border-radius: $game-card--inner-radius $game-card--inner-radius 0 0;
    }

    &:last-child {
      border-radius: 0 0 $game-card--inner-radius $game-card--inner-radius;
    }
  }

  .Avatar--medium,
  .GameIcon--medium {
    flex: 0 0 auto;
  }
}

.GameCard--public {
  @extend %GameCard;
}

.GameCard__section {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  min-width: 0; // fix ellipsis bug
}

.GameCard__section + .GameCard__section {
  border-top: 1px solid $base-color--xlight;
}

.GameCard__info {
  flex: 1 1 100%;
  line-height: 1.2;
  min-width: 0; // fix ellipsis bug
  padding-left: $panel-spacing / 2;
}

.GameCard__league-name {
  @include ellipsis;
  @include responsive-font(large, 1);
  color: $primary-color;
  font-weight: $font-bold;
  margin: 0;
}

%GameCard__small-text {
  @include responsive-font(normal, 1);
  display: inline-block;
  color: $base-color--light;
  font-weight: $font-normal;
}

.GameCard__season-name {
  @extend %GameCard__small-text;
}

.GameCard__week {
  @extend %GameCard__small-text;

  &::before {
    color: $primary-color;
    font-weight: $font-bold;
    content: '\00A0|\00A0';
  }
}

.GameCard__avatar {
  @extend %Avatar;
  flex: 0 0 auto;
  width: 4rem;
  height: 4rem;
}

.GameCard__results {
  flex: 1 1 100%;
  line-height: 1.2;
  min-width: 0; // fix ellipsis bug
  padding-left: $panel-spacing / 2;
}

.GameCard__earnings {
  @include ellipsis;
  @include responsive-font(large);
  font-weight: $font-bold;
}

.GameCard__rank {
  @extend %GameCard__small-text;
  color: $base-color--xlight;
}

// NOTIFICATIONS

%GameCard__notification {
  @include responsive-font(normal, 1.2);
  align-items: center;
  color: $page-color;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: $panel-spacing * .7;
  padding-right: $type-spacing--tight + $game-card--go-icon-size-large + $panel-spacing;
  padding-top: $panel-spacing * .7;
  position: relative;

  [grid-el~='>500'] & {
    padding-right: $type-spacing--tight + $game-card--go-icon-size-small + $panel-spacing;
  }
}

.GameCard__notification--week {
  @extend %GameCard__notification;
  background: $base-color--xlight;
}

.GameCard__notification,
.GameCard__notification--season {
  @extend %GameCard__notification;
  background: $accent-color;
}

.GameCard__notification-info-icon {
  height: $game-card--go-icon-size-small;
  margin: -.2em .2em 0 0;
  width: $game-card--go-icon-size-small;
  vertical-align: middle;
}

.GameCard__notification-text {
  flex: 0 0 100%;
  font-weight: $font-normal;
}

.GameCard__notification-subtext {
  flex: 0 0 100%;
  font-weight: $font-light;
}

.GameCard__notification-go-icon {
  height: $game-card--go-icon-size-large;
  position: absolute;
  right: $panel-spacing;
  top: calc(50% - #{$game-card--go-icon-size-large / 2});
  width: $game-card--go-icon-size-large;
}

// CUSTOM LEAGUE STYLES

.GameCard--private {
  @extend %GameCard;

  .GameCard__league-name {
    @include responsive-font(large);
    text-transform: none;
  }
}


// LARGE COLUMN STYLES

// hide game cards before the grid size loads
[grid-el=""] > #{$game-card-class-selector} {
  display: none;
}

[grid-el~='>500'] {

  #{$game-card-class-selector}  .Avatar--medium {
    order: 2;
  }

  .GameCard__section {
    border-radius: $game-card--inner-radius 0 0 $game-card--inner-radius;
    flex: 0 0 50%;
  }

  .GameCard__section + .GameCard__section {
    border-top: 0;
    border-radius: 0 $game-card--inner-radius $game-card--inner-radius 0;
    text-align: right;

    .GameCard__results {
      padding-left: 0;
      padding-right: $panel-spacing / 2;
    }
  }

  .GameCard__notification--week,
  .GameCard__notification--season {
    padding-right: $type-spacing--tight + $game-card--go-icon-size-small + $panel-spacing;
  }

  .GameCard__notification-text {
    flex: 1 1 auto;
  }
  .GameCard__notification-subtext {
    flex: 1 1 auto;
    padding-left: $panel-spacing;
    text-align: right;
  }

  .GameCard__notification-go-icon {
    height: $game-card--go-icon-size-small;
    top: calc(50% - #{$game-card--go-icon-size-small / 2});
    width: $game-card--go-icon-size-small;
  }
}
