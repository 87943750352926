.AlertScreen {
  text-align: center;

  .GameIcon--large {
    margin-bottom: .9375rem;
  }
}

.AlertScreen__secondary-message {
  font-size: 1.5rem;
  font-weight: 300;
}

.AlertScreen__info-graphic {
  margin: 0 auto;
  width: 45rem;
  text-align: center;

  img {
    width: 100%;
  }
}
