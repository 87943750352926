.Field {
  &:not(:first-child) {
    margin-top: map-get($grid-spacing, tight);
  }
  &:not(:last-child) {
    margin-bottom: map-get($grid-spacing, tight);
  }
}

.Field__label {
  @include responsive-font(large);
  display: block;
  font-weight: $font-normal;
  margin-bottom: $type-spacing--tight;

  &.is-required::after {
    @include responsive-font(small);
    content: ' (required)';
    text-transform: uppercase;
    color: $base-color--xlight;
  }
}

.Field__error {
  @include responsive-font(small);
  color: $accent-color;
}
