%GameIcon {
  &:not(img) {
    background: $page-color;
  }
}

.GameIcon--small {
  @extend %Avatar--small;
  @extend %GameIcon;
}

.GameIcon--medium {
  @extend %Avatar--medium;
  @extend %GameIcon;
}

.GameIcon--large {
  @extend %Avatar--large;
  @extend %GameIcon;
}
