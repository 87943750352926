// # Grid
// Responsive grid system that uses html attributes to define grid behavior.

// VARS (override in your app)

$grid-tag: 'grid' !default;
$grid-content-tag: 'grid-el' !default;
$grid-sort-attrs: (first: 'first', higher: 'higher', lower: 'lower', last: 'last') !default;
$grid-breakpoints: (phone: 0, tablet: 500px, desktop: 900px) !default;
$grid-spacing: (loose: 2rem, tight: 1rem) !default;
$grid-columns: ('1/1': 100%, '1/5': 20%, '1/4': 25%, '1/3': 33.333%, '1/2': 50%, '2/3': 66.666%, '3/4': 75%) !default;

// Alignment (don't override)

$grid-align: ('align-middle': center, 'align-bottom': flex-end, 'align-baseline': baseline);
$grid-justify: ('align-left': flex-start, 'align-center': center, 'align-right': flex-end, 'spaced': space-between);

// MIXINS

@mixin grid-breakpoint-rules($name, $width) {
  @media (min-width: $width) {
    @include grid-order($name);
    @include grid-column-spacing($name);
    @each $spacing-name, $spacing-val in $grid-spacing {
      @include grid-column-spacing($name, $spacing-name, $spacing-val);
    }
  }
}

@mixin grid-order($name) {
  $orders: (first: -2, higher: -1, lower: 1, last: 2);
  @each $sort, $order in $orders {
    [#{$grid-tag}~='#{map-get($grid-sort-attrs, $sort)}#{$name}'] {
      order: $order;
    }
  }
}

@mixin grid-spacing($spacing-name, $spacing-value) {
  [#{$grid-tag}~='#{$spacing-name}'] {
    margin: 0 - $spacing-value 0 0 0 - $spacing-value;
    &:not(:first-child) {
      margin-top: 0;
    }
    > * {
      display: block;
      padding: $spacing-value 0 0 $spacing-value;
    }
  }
}

@mixin grid-column-spacing($name, $spacing-name: null, $spacing-value: null) {
  $selector: if($spacing-name, '[#{$grid-tag}~="#{$spacing-name}"]', '[#{$grid-tag}]');

  #{$selector} {
    > [#{$grid-tag}~='shrink#{$name}'] {
      flex: 0 0 auto;
    }
    > [#{$grid-tag}~='grow#{$name}'] {
      flex: 100 100 auto;
    }
    @each $width, $percent in $grid-columns {
      > [#{$grid-tag}~='#{$width}#{$name}'] {
        flex-basis: if($spacing-value, calc(#{$percent} - #{$spacing-value}), #{$percent});
        min-width: 0; // apparently this needs to be set here as well to fix issues in chrome
      }
    }
  }
}

// BASE STYLES

#{'[#{$grid-tag}]'} {
  box-sizing: content-box !important; // necessary for IE calc() width fix
  display: flex;
  flex-direction: row;
  flex-grow: 100;
  flex-shrink: 1;
  flex-wrap: wrap;
  position: relative;

  > * {
    box-sizing: border-box;
  }
}

[#{$grid-tag}~='nowrap'] {
  flex-wrap: nowrap;
}

@each $name, $align in $grid-align {
  [#{$grid-tag}~='#{$name}'] {
    align-items: $align;
  }
}
@each $name, $justify in $grid-justify {
  [#{$grid-tag}~='#{$name}'] {
    justify-content: $justify;
  }
}

[#{$grid-content-tag}] {
  display: block;
  flex-basis: 0; // no idea why this works
  min-width: 0;
}

[#{$grid-tag}~='fixed'] [#{$grid-content-tag}] {
  flex-grow: 0;
}

// SPACING

@each $spacing-name, $spacing-val in $grid-spacing {
  @include grid-spacing($spacing-name, $spacing-val);
}

// BREAKPOINTS

$breakpoint-names: map-keys($grid-breakpoints);
$breakpoint-widths: map-values($grid-breakpoints);

@mixin grid-breakpoint($min: null, $max: null) {
  @if $min {
    @if $max {
      @media (min-width: $min) and (max-width: $max - 1) {
        @content;
      }
    } @else {
      @media (min-width: $min) {
        @content;
      }
    }
  } @else if $max {
    @media (max-width: $max - 1) {
      @content;
    }
  }
}

@each $breakpoint in $breakpoint-names {
  $i: index($breakpoint-names, $breakpoint);
  $width: nth($breakpoint-widths, $i);
  $next-width: if($i < length($breakpoint-names), nth($breakpoint-widths, $i + 1), null);
  $hide-at-attr: 'hide@#{$breakpoint}';
  $hide-at-class: '.hide\\@#{$breakpoint}';
  $hide-only-at-attr: 'hide-only@#{$breakpoint}';
  $hide-only-at-class: '.hide-only\\@#{$breakpoint}';
  $show-at-attr: 'show@#{$breakpoint}';
  $show-at-class: '.show\\@#{$breakpoint}';
  $show-only-at-attr: 'show-only@#{$breakpoint}';
  $show-only-at-class: '.show-only\\@#{$breakpoint}';

  @include grid-breakpoint-rules('@#{$breakpoint}', $width);

  // hide-only - hide between current and next
  @include grid-breakpoint($width, $next-width) {
    #{$hide-only-at-class},
    [#{$grid-tag}~='#{$hide-only-at-attr}'] {
      display: none !important;
    }
  }

  // show-only - hide below current and at next
  @include grid-breakpoint(null, $width) {
    #{$show-only-at-class},
    [#{$grid-tag}~='#{$show-only-at-attr}'] {
      display: none !important;
    }
  }
  @include grid-breakpoint($next-width) {
    #{$show-only-at-class},
    [#{$grid-tag}~='#{$show-only-at-attr}'] {
      display: none !important;
    }
  }

  // hide - hide at current and above
  @include grid-breakpoint($width) {
    #{$hide-at-class},
    [#{$grid-tag}~='#{$hide-at-attr}'] {
      display: none !important;
    }
  }

  // show - hide below current
  @include grid-breakpoint(null, $width) {
    #{$show-at-class},
    [#{$grid-tag}~='#{$show-at-attr}'] {
      display: none !important;
    }
  }

}
