$team-summary-spacing-vert: $panel-spacing * .7;
$team-summary-spacing-horiz: $panel-spacing;
$team-summary-spacing-vert-tight: $panel-spacing--tight * .7;
$team-summary-spacing-horiz-tight: $panel-spacing--tight;


.TeamSummary {
  @include responsive-font(large);
  align-items: center;
  background: $primary-color;
  border-radius: $panel-radius;
  color: $page-color;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  > * {
    flex: 0 0 auto;
    min-width: 0; // fixes sizing bug
    margin: $team-summary-spacing-vert-tight $team-summary-spacing-horiz-tight;

    @include at-tablet-width {
      margin: $team-summary-spacing-vert $team-summary-spacing-horiz;
    }
  }
}

.TeamSummary__avatar {
  @extend %Avatar--medium;
  margin: $team-summary-spacing-vert 0 $team-summary-spacing-vert $team-summary-spacing-vert;

  @include below-tablet-width {
    display: none;
  }
}

.TeamSummary__name {
  @include responsive-font(xlarge, 1.2);
  flex: 1 1 auto;
  font-weight: $font-bold;
  margin-left: .5em;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.TeamSummary__info {
  text-align: right;
}

.TeamSummary__info-primary {
  @include responsive-font(xlarge);
  font-weight: $font-bold;
}

.TeamSummary__info-secondary {
  @include responsive-font(normal);
  font-weight: $font-light;
  opacity: .7;
}

// .TeamSummary__item {
//   @extend %summary-item;
//
//   &:last-child {
//     @include at-tablet-width {
//       align-items: flex-end;
//     }
//   }
// }
