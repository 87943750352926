.Overlay {
  background-color: $page-color;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.Overlay__header {
  @extend %PageSection;
  background: $primary-color;
  color: $primary-color--xxxlight;
  flex: 0 0 auto;
  padding-bottom: $page-margin-phone;
  padding-top: $page-margin-phone;

  @include at-tablet-width {
    padding-bottom: $page-margin;
    padding-top: $page-margin;
  }
}

.Overlay__title {
  font-size: 1.4rem;
  font-weight: $font-light;
  line-height: .9em;
  margin: 0;
}

.Overlay__content {
  @extend %PageSection;
  @include overflow-scrolling;
  overflow: auto;
}

.Overlay__close {

  .Icon {
    width: 1em;
    height: 1em;
  }
}
