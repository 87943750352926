$chatter-header-height: 3.6em;
$chatter-header-padding: .75em;
$chatter-room-title-height: 1.4em;
$chatter-section-width: 570px;
$chatter-section-spacing: 15px;
$chatter-height-transition: height 500ms ease-in-out;
$chatter-section-width-full: $chatter-section-width + ($chatter-section-spacing * 2);

%chatter-section {
  padding: $chatter-section-spacing;
  width: 100%;

  @media (min-width: #{$chatter-section-width-full}) {
    padding: $chatter-section-spacing calc(50% - #{$chatter-section-width-full / 2});
  }
}

.Chatter {
  background: $page-color;
  bottom: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, .25);
  display: flex;
  flex-direction: column;
  height: $chatter-header-height;
  position: fixed;
  transition: $chatter-height-transition;
  width: 100%;
  z-index: $boc-nav--z-index + 1; // drawer covers the nav

  &.is-active {
    height: 100vh;
  }

  // hack to add extra white below the page for iOS scroll issues
  &::after {
    content: '';
    display: block;
    height: 0;
    outline: $chatter-header-height solid $page-color;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: -1;
  }

  &__header {
    align-items: center;
    box-shadow: 0 1px 0 $primary-color;
    cursor: pointer;
    display: block;
    flex: 0 0 auto;
    font-size: 1em;
    line-height: 1;
    min-height: $chatter-header-height;
    padding: $chatter-header-padding;
    position: relative;
    text-align: center;
    z-index: 2;
  }

  &__header-count {
    align-items: center;
    background: url('/img/icons/chatter-bubble.svg') center center no-repeat;
    background-size: 3.6em;
    color: $page-color;
    display: flex;
    height: $chatter-header-height;
    justify-content: center;
    left: 0;
    padding: 0 .7em .7em 0;
    position: absolute;
    top: -$chatter-header-padding;
    width: $chatter-header-height;
  }

  &__header-title {
    font-size: .9em;
    height: .9em;
    margin-bottom: .2em;
  }

  &__header-link {
    color: $accent-color;
    display: block;
    font-size: .8em;
  }

  &__room-title {
    @include ellipsis;
    @include responsive-font(xxxlarge);
    color: $primary-color;
    font-weight: $font-light;
    height: 0;
    line-height: $chatter-room-title-height;
    transition: $chatter-height-transition;

    .Chatter.is-active & {
      height: $chatter-room-title-height;
      transition: $chatter-height-transition;
    }
  }

  &__banner {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    min-height: 2.1em;
    padding-left: 4.2em;
    position: relative;
    text-align: left;
  }

  &__posts {
    align-items: flex-start;
    display: flex;
    flex: 100 100 0%;
    flex-direction: column-reverse;
    height: 0;
    overflow: auto;
  }

  &__footer {
    @extend %chatter-section;
    background: $alternate-color--xxlight;
    flex: 0 1 auto;
    overflow: hidden;
    position: relative;
  }

}

.ChatterPost {
  @extend %chatter-section;

  &:not(:last-child) {
    border-top: $boc-border--thin solid $base-color--xxlight;
  }

  &__content {
    padding-left: $avatar-size-small + 10px;
    position: relative;
  }

  .Avatar--small {
    position: absolute;
    top: .3em;
    left: 0;
  }

  &__info {
    line-height: 1;
  }

  &__user {
    @include responsive-font(small);
    color: $primary-color;
    margin-right: .6em;
  }

  &__date {
    @include responsive-font(small);
    color: $base-color--light;
    font-weight: $font-light;
  }

  &__message {
    @include responsive-font(normal);
    color: $base-color;
  }

  &__link {
    @include responsive-font(small);
    color: $accent-color;
    cursor: pointer;
  }

  &__flagged {
    @include responsive-font(small);
    color: $base-color--xlight;
    font-weight: $font-light;
  }

  &__sending {
    @include responsive-font(small);
    color: $base-color--light;
  }
}
