$game-info--icon-size: 6rem;
$game-info--bullet-size: 1em;

.GameHelp__header {
  text-align: center;
}

.GameHelp__title {
  @include responsive-font(xxlarge);
  color: $primary-color;
  text-align: center;
}

.GameHelp__info {
  background: $primary-color--xxxlight;
  border-radius: .25rem;
  padding: $panel-spacing;
  text-align: center;

  @include at-tablet-width {
    text-align: left;

    .Button--large-primary { width: 100%; }
  }
}

.GameHelp__features {
  @include responsive-font(large);
  color: $primary-color;
  text-align: left;
}

.GameHelp__feature {
  padding-left: $game-info--bullet-size * 1.5;

  &::before {
    content: '\2713';
    float: left;
    width: $game-info--bullet-size * 1.5;
    height: $game-info--bullet-size;
    margin-left: $game-info--bullet-size * -1.5;
  }

  &:not(:first-child) { margin-top: .5em; }
}
