#movie-container {
	background-color: #FFFFFF;
	padding-bottom: 1.666rem;
	padding-right: 1.666rem;
	margin-top: 20rem;
}

#MoviePageHeroImage {
	background-size: cover;
	background-color: #FFF;
	height: 50rem;
	width: 100%;
	position: absolute;
	top: 50px;
	left: 0;
  background-repeat: none;
  background-position: center top;
}

#MoviePageHeroImageFade {
	bottom: 0;
	top: 50%;
	position: absolute;
	width: 100%;
	background-image: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
	background-image: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background-image: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

#MoviePage_MovieTitle {
	font-size: 3rem;
	font-weight: 200;
	color: #168095;
	line-height: 3.625rem;
	margin-bottom: 1.25rem;
}

#MoviePage_TotalEarnings {
	font-size: 1.125rem;
	font-weight: 200;
	line-height: 1.5rem;
}

#MoviePage_TotalEarningsNumber {
	font-size: 3rem;
	font-weight: 200;
	line-height: 3.625rem;
	margin-bottom: 1.875rem;
}

#movie-container .MoviePopup__summary-items {
	margin-bottom: 1.125rem;
}

#MoviePage_CastCrew {
	padding: 1.125rem;
}

#MoviePage_CastCrew .Text--small {
	margin-bottom: 1.125rem;
}

#MoviePage_Poster img {
	width: 100%;
}


