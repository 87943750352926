@mixin avatar-size($size) {
  font-size: $size * .3;
  width: $size;
  height: $size;
}

.Avatar,
%Avatar {
  align-items: center;
  background-color: $primary-color--xlight;
  background-size: cover;
  border-radius: 50%;
  color: $page-color;
  display: inline-flex;
  font-weight: $font-normal;
  justify-content: center;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  z-index: 0;

  &[alt]::after {
    content: attr(alt);
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
  }
}

.Avatar--nav {
  @extend %Avatar;
  @include avatar-size(2.4em);
}

%Avatar--small,
.Avatar--small {
  @extend %Avatar;
  @include avatar-size($avatar-size-small);
}

%Avatar--medium,
.Avatar--medium {
  @extend %Avatar;
  @include avatar-size($avatar-size-small);

  @include at-desktop-width {
    @include avatar-size($avatar-size-medium);
  }
}

%Avatar--large,
.Avatar--large {
  @extend %Avatar;
  @include avatar-size($avatar-size-medium);

  @include at-desktop-width {
    @include avatar-size($avatar-size-large);
  }
}
