.GamePromo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $primary-color;
  border-radius: $panel-radius;
  text-align: center;
  background: #fff;
  padding: ($panel-spacing * 1.5) $panel-spacing;
}

.GamePromo__title {
  @include responsive-font(xxlarge);
  font-weight: $font-bold;
  color: $primary-color;
  margin-top: .5rem;
}

.GamePromo__icon {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  margin: 1rem;
  background: $primary-color--xxlight;
}

.GamePromo__features {
  color: $base-color;
  flex: 100 0 auto;
  font-weight: $font-light;
  margin: 0 0 1em;
}
