// MAIN NAV BAR

.BocNav {
  align-items: center;
  background: $primary-color;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 0 $boc-nav--spacing;
  text-transform: uppercase;
  top: 0;
  width: 100%;
}

.BocNav__logo {
  color: #fff;
  display: block;
  flex: 0 0 auto;
  margin: 0 $boc-nav--spacing;

  .BocAbbrev {
    display: block;

    @include below-tablet-width {
      height: $boc-nav--height;
      width: 32px; // matches text size
    }

    @include at-tablet-width {
      height: $boc-nav--height-large;
    }
  }
}

.BocNav__links {
  @include responsive-font(nav, $boc-nav--height);
  display: flex;
  flex: 0 0 auto;
}

.BocNav__secondary-links {
  @include responsive-font(normal, $boc-nav--height);
  display: flex;
  flex: 10 0 auto;
  justify-content: flex-end;
  align-items: center;
  font-weight: $font-light;
  padding: 0 $boc-nav--spacing;
}

.BocNav__link {
  color: $primary-color--xlight;
  padding: 0 $boc-nav--spacing;
  position: relative;

  &.is-selected {
    color: #fff;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -$boc-nav--carret;
      border-left: $boc-nav--carret solid transparent;
      border-right: $boc-nav--carret solid transparent;
      border-bottom: $boc-nav--carret solid #fff;
    }
  }

  @include at-tablet-width {
    line-height: $boc-nav--height-large;
  }
}

.BocNav__login {
  @include responsive-font(nav, $boc-nav--height);
  color: $highlight-color;
  padding: 0 $boc-nav--spacing;
}

.BocNav__avatar {
  height: $boc-nav--height - $boc-nav--spacing;
  width: $boc-nav--height - $boc-nav--spacing;
  margin-right: $boc-nav--spacing;

  &.is-active {
    outline: 8px solid $primary-color--xxlight;
    box-shadow: 0 0 0 8px $primary-color--xxlight;
  }

  @include at-tablet-width {
    height: $boc-nav--height-large - $boc-nav--spacing;
    width: $boc-nav--height-large - $boc-nav--spacing;
  }
}

.BocNav__more {
  color: $primary-color--xlight;
  padding: 0 $boc-nav--spacing;
}


// GAME NAV

.SubNav {
  align-items: baseline;
  border-bottom: 1px solid $accent-color;
  display: flex;
  flex-direction: row;
  height: $boc-nav--height;
  padding-bottom: .05px;
  padding-top: .05px;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: $boc-nav--z-index;

  + * {
    margin-top: $boc-nav--height;
    @include at-tablet-width {
      margin-top: $boc-sub-nav--height;
    }
  }

  @include at-tablet-width {
    height: $boc-sub-nav--height;
  }
}

.SubNav__title {
  @include responsive-font(large, $boc-nav--height);
  color: $accent-color;
  flex: 0 1 auto;
  font-size: $boc-nav--font-size-large;
  font-weight: $font-bold;
  overflow: hidden;
  text-overflow: ellipsis;

  @include at-tablet-width {
    line-height: $boc-sub-nav--height;
  }
}

.SubNav__select {
  flex: 1 1 auto;
  padding: 0 1em 0 .2em;

  select {
    @include responsive-font(normal, 1);
    appearance: none;
    background-image: url('/img/svg-sprite/chevron-down.svg');
    background-position: right 50%;
    background-repeat: no-repeat;
    background-size: 1em;
    border: 0;
    color: $base-color--light;
    cursor: pointer;
    font-weight: $font-light;
    max-width: 50vw;
    outline: none;
    padding: 0 1.2em 0 .5em;
    text-transform: uppercase;
  }
}

.SubNav__more {
  flex: 0 0 auto;
  margin: -.5em -.5em -.5em 0;
  padding: .5em;

  .Icon {
    color: $base-color--light;
    position: relative;
    top: -.1em;
  }
}

.SubNav__links {
  color: $base-color--xlight;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  justify-content: flex-end;
}

.SubNav__link {
  flex: 0 0 auto;
  padding: 0 .5em;

  &.is-active {
    color: $accent-color;
  }
}


// NAV SUB MENUS

%BocNavMenu {
  @include responsive-font(normal, $boc-nav--height);
  border-bottom: 1px solid $primary-color;
  display: none;
  left: 0;
  padding: $boc-nav--spacing 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $boc-nav--z-index + 1;

  &.is-active { display: block; }

  @include at-tablet-width {
    line-height: $boc-line-height;
    top: $boc-nav--height;
  }
}

.BocNavMenu__header {
  align-items: center;
  display: flex;
  top: -$boc-nav--spacing;
  padding: 0 $boc-nav--spacing * 2;
  position: relative;
}

.BocNavMenu__title {
  flex: 1 1 auto;
}

.BocNavMenu__close {
  color: inherit;
  flex: 0 0 auto;
  opacity: .5;
}

.BocNavMenu__link {
  color: inherit;
  display: block;
  padding: $boc-nav--spacing / 2 $boc-nav--spacing * 2;
}


// SUB NAV STYLES
.BocNavMenu--main,
.BocNavMenu--user {
  @extend %BocNavMenu;
  background: $primary-color--xxlight;
  border-bottom-color: $primary-color;
  color: $primary-color;
  position: fixed;

  .BocNavMenu__header {
    background: $primary-color;
    color: #fff;
  }
}

.BocNavMenu--sub {
  @extend %BocNavMenu;
  background: $page-color;
  border-bottom-color: $accent-color;
  color: $base-color--light;
  position: fixed;

  .BocNavMenu__header {
    background: $page-color;
    border-bottom: 1px solid $base-color--xxxlight;
    color: $accent-color;
  }
}

@include at-tablet-width {
  .BocNavMenu--user {
    width: auto;
    left: auto;
    right: 0;
    top: $boc-nav--height-large;
  }
  .BocNavMenu__header {
    display: none;
  }
}


// OVERLAY
@keyframes BOC-OVERLAY-IN {
  0% {
    height: 0;
    opacity: 0;
  }
  1% {
    height: 100%;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes BOC-OVERLAY-OUT {
  0% {
    height: 100%;
    opacity: 1;
  }
  99% {
    height: 100%;
    opacity: 0;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}

.BocNavMenu__overlay {
  content: '';
  background: rgba($primary-color--dark, .7);
  height: 0;
  left: 0;
  position: fixed;
  top: $boc-nav--height;
  width: 100%;
  z-index: $boc-nav--z-index - 1;

  @include at-tablet-width {
    background: transparent;
  }

  &.is-not-active {
    animation: BOC-OVERLAY-OUT 300ms;
  }
  &.is-active {
    height: calc(100vh - #{$boc-nav--height});
    animation: BOC-OVERLAY-IN 300ms;
  }
}
