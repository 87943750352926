// <nav class="BocTabs">
//   <a class="BocTabs__tab is-active">BOC Badges</a>
//   <a class="BocTabs__tab">Activity</a>
//   <a class="BocTabs__tab">History</a>
//   <a class="BocTabs__tab">Favorites</a>
// </nav>

$boc-tabs-height: 2.25em;
$boc-tabs-carret: .8em;
$boc-tabs-spacing: 1.5em;
$boc-tabs-color: $accent-color;
$boc-tabs-border: .15em solid $primary-color;

%BocTabs__carret {
  border-bottom: $boc-tabs-carret solid $primary-color;
  border-left: $boc-tabs-carret solid transparent;
  border-right: $boc-tabs-carret solid transparent;
  bottom: 0;
  content: '';
  left: 50%;
  margin-left: -$boc-tabs-carret;
  position: absolute;
}

.BocTabs {
  border-bottom: $boc-tabs-border;
  display: none; // no tabs on phone (scroll to see section content)
  font-size: 1rem;
  overflow: hidden;

  &:not(:first-child) { margin-top: $boc-tabs-spacing; }
  &:not(:last-child) { margin-bottom: $boc-tabs-spacing; }

  @include at-tablet-width {
    display: block;
  }
}

.BocTabs__tab {
  color: $accent-color;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 1.4em;
  font-weight: $font-normal;
  line-height: 1;
  margin: 0 $boc-tabs-spacing / 2;
  padding-bottom: $boc-tabs-height - 1;
  text-align: center;
  transition: color 300ms;

  &:first-child { margin-left: 0; }
  &:last-child { margin-right: 0; }

  &:hover {
    color: $accent-color--dark;
  }

  &.is-active {
    color: $primary-color;
    position: relative;

    &::before {
      @extend %BocTabs__carret;
    }
  }
}

.BocTabs__header {
  @extend %SectionTitle;

  ~ .BocTabs__header {
    margin-top: $loose-spacing;
    position: relative;
  }

  @include at-tablet-width { display: none; }
}

.BocTabs__header,
.BocTabs__content {
  @include at-tablet-width {
    &:not(.is-active) {
      display: none;
    }
  }
}
