$movie-card-font-large: 40px;
$movie-card-font-medium: 24px;
$movie-card-font-small: 18px;
$movie-card-font-tiny: 14px;

$movie-card-line-height-cap: 50px;
$movie-card-line-height-large: 64px;
$movie-card-line-height-medium: 36px;
$movie-card-line-height-small: 28px;

%MovieCard {
  border-radius: 3px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
}

%MovieCard-pick__content {
  background-color: inherit;
  height: 100%;
  left: 0;

  position: absolute;
  top: 0;
  width: 100%;
}

@mixin movie-card-cap($bg, $color) {
  .MovieCard__cap {
    background: $bg;
    color: $color;
  }
}

@mixin movie-card-primary($bg, $color) {
  .MovieCard__release-date,
  .MovieCard__earnings {
    background-color: $bg;
    color: $color;
  }
}

@mixin movie-card-secondary($bg, $color) {
  .MovieCard__secondary-result,
  .MovieCard__remove-movie {
    background-color: $bg;
  }

  .MovieCard__secondary-result {
    color: $color;
  }
}

@mixin movie-card-colors(
  $cap-bg, $cap-color,
  $pick-bg, $pick-color,
  $primary-bg, $primary-color,
  $secondary-bg, $secondary-color,
  $data-header-bg, $data-header-color,
  $data-row-bg, $data-row-color
) {

  @include movie-card-cap($cap-bg, $cap-color);
  @include movie-card-primary($primary-bg, $primary-color);
  @include movie-card-secondary($secondary-bg, $secondary-color);

  .MovieCard__pick {
    background-color: $pick-bg;
  }

  .MovieCard__pick-message {
    color: $pick-color;
  }

  .MovieCard__history {
    background-color: $data-header-bg;
    color: $data-header-color;
  }

  .MovieCard__history-numbers {
    background-color: $data-row-bg;
    color: $data-row-color;
  }

  .MovieCard__averages {
    color: $data-row-color;
  }
}

// MOVIE CARD

.MovieCard {
  @extend %MovieCard;
  @include movie-card-colors(
    $cap-bg: $primary-color,
    $cap-color: $page-color,
    $pick-bg: $primary-color--xxxlight,
    $pick-color: $primary-color,
    $primary-bg: $primary-color--xxlight,
    $primary-color: $primary-color,
    $secondary-bg: $primary-color--xxxlight,
    $secondary-color: $primary-color,
    $data-header-bg: $primary-color--xxlight,
    $data-header-color: $page-color,
    $data-row-bg: $base-color--xxxlight,
    $data-row-color: $primary-color
  )

  &.is-incomplete {
    @include movie-card-cap($accent-color, $page-color);
    @include movie-card-primary($accent-color, $page-color);
  }

  &.is-hidden {
    @include movie-card-primary($primary-color--xxxlight, $primary-color);
  }

  &.is-final,
  &.is-void {
    @include movie-card-primary($primary-color, $page-color);
  }

  &.is-empty {
    @include movie-card-cap($primary-color--xxlight, $page-color);
    cursor: pointer;
  }
}

// CHEAP SEAT

.MovieCard--cheap-seat {
  @extend %MovieCard;
  @include movie-card-colors(
    $cap-bg: $alternate-color--xdark,
    $cap-color: $page-color,
    $pick-bg: $alternate-color--xxlight,
    $pick-color: $alternate-color--xdark,
    $primary-bg: $alternate-color--xlight,
    $primary-color: $alternate-color--xdark,
    $secondary-bg: $alternate-color--xxlight,
    $secondary-color: $alternate-color--xdark,
    $data-header-bg: $alternate-color--xxlight,
    $data-header-color: $page-color,
    $data-row-bg: $alternate-color--xxxlight,
    $data-row-color: $alternate-color--xdark
  )

  .MovieCard__pick-image {
    &::before {
      color: $alternate-color--xdark;
    }
  }

  &.is-incomplete {
    @include movie-card-cap($accent-color, $page-color);
    @include movie-card-primary($accent-color, $page-color);
  }

  &.is-hidden {
    @include movie-card-primary($alternate-color--xxlight, $alternate-color--xdark);
  }

  &.is-final,
  &.is-void {
    @include movie-card-primary($alternate-color--xdark, $page-color);
  }

  &.is-empty {
    @include movie-card-cap($alternate-color, $page-color);
    cursor: pointer;
  }
}

// DATE ALERT

.MovieCard--date-alert {
  @extend %MovieCard;
  @include movie-card-colors(
    $cap-bg: $highlight-color,
    $cap-color: $page-color,
    $pick-bg: $highlight-color,
    $pick-color: $alternate-color--xdark,
    $primary-bg: $highlight-color,
    $primary-color: $alternate-color--xdark,
    $secondary-bg: $highlight-color--light,
    $secondary-color: $alternate-color--xdark,
    $data-header-bg: $highlight-color--light,
    $data-header-color: $page-color,
    $data-row-bg: $base-color--xxxlight,
    $data-row-color: $alternate-color
  )
  cursor: pointer;
}

// HEADER COMPONENTS

.MovieCard__cap {
  border-radius: 3px 3px 0 0;
  font-size: $movie-card-font-large;
  font-weight: 600;
  line-height: $movie-card-line-height-cap;
  position: relative;

  .Icon {
    height: $movie-card-font-large * .5;
    margin-bottom: 0;
    width: $movie-card-font-large * .5;
  }
}

.MovieCard__select {
  @extend %Button--primary;
  @extend %Button--large;
  border-radius: 3px 3px 0 0;
  display: block;
  padding: .5em;
  width: 100%;
}

.MovieCard__rank-actual {
  font-size: $movie-card-font-small;
  font-weight: 400;
  line-height: $movie-card-line-height-medium;
  right: ($movie-card-line-height-medium - $movie-card-font-small) / 2;
  position: absolute;
}

.MovieCard__cap-mobile {
  background-position: 50% calc(50% - 1.375rem);
  background-size: cover;
  padding: 37.5% 0 0;

  > * {
    background-color: $page-color;
    color: $primary-color;
    font-size: $movie-card-font-medium;
    font-weight: 600;
    line-height: $movie-card-line-height-medium;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// POSTER COMPONENTS

.MovieCard__pick {
  position: relative;
  display: block;
  border-top: 1px solid $page-color;
  border-bottom: 1px solid $page-color;
  padding-top: 150%;
  height: 0;
  overflow: hidden;
}

.MovieCard__pick-image {
  @extend %MovieCard-pick__content;
  display: block;

  &::before,
  &::after {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    white-space: normal;
  }

  &::before {
    background-color: $base-color;
    color: $primary-color;
    content: attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt) + attr(alt);
    display: block;
    filter: blur(.05em);
    font-size: 4vw;
    left: -20%;
    letter-spacing: .2em;
    line-height: .9;
    transform: scale(1.2) rotate(-30deg);
    text-transform: uppercase;
    width: 140%;
    word-break: break-all;

    @include at-desktop-width {
      font-size: $movie-card-font-large;
    }
  }

  &::after {
    background: linear-gradient(to bottom right, rgba($primary-color, .2) -5%, rgba($base-color, 1) 105%);
    color: $base-color--xxxlight;
    content: attr(alt);
    font-family: $boc-base-font;
    font-size: $movie-card-font-medium;
    font-weight: 700;
    left: 0;
    line-height: $movie-card-font-medium;
    padding: .5em;
    right: 0;
  }
}

.MovieCard__pick-message,
.MovieCard__pick-link {
  @extend %MovieCard-pick__content;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: $movie-card-font-small;
  font-weight: 300;
  justify-content: center;
  line-height: $movie-card-font-small;
  padding: $movie-card-font-small;
  white-space: normal;
}

.MovieCard__pick-link {
  color: $accent-color;
}

// PREDICITION COMPONENTS (Top 5 only)

.MovieCard__weekend-num {
  background-color: $primary-color;
  border-bottom: 1px solid #fff;
  color: $page-color;
  font-size: $movie-card-font-tiny;
  font-weight: 600;
  line-height: $movie-card-line-height-small;
  text-transform: uppercase;
  overflow: hidden;
}

.MovieCard__enter-prediction {
  appearance: none;
  background: $page-color;
  border: 2px solid $primary-color;
  color: $primary-color;
  font-size: $movie-card-font-medium;
  font-weight: 600;
  height: $movie-card-line-height-large;
  line-height: 1.5rem;
  padding: (($movie-card-line-height-large - $movie-card-font-medium) / 2) 0;
  text-align: center;
  width: 100%;

  &::placeholder {
    color: $accent-color;
    font-size: $movie-card-font-small;
    font-weight: 100;
  }
}

// DETAILS COMPONENTS

.MovieCard__release-date,
.MovieCard__earnings {
  overflow: hidden;
  line-height: $movie-card-line-height-large;
}

.MovieCard__release-date {
  font-weight: 300;
  font-size: $movie-card-font-small;
}

.MovieCard__release-date--select {
  display: block;
  font-size: $movie-card-font-small;
  font-weight: 300;
  margin-top: .2em;
}

.MovieCard__release-day {
  font-weight: 600;
  font-size: $movie-card-font-tiny;
  text-transform: uppercase;
}

.MovieCard__week-count,
.MovieCard__week-count--negative {
  color: $primary-color;
  display: block;
  font-size: $movie-card-font-tiny;
  text-transform: uppercase;
}

.MovieCard__week-count--negative {
  color: $highlight-color--dark;
}

.MovieCard__earnings {
  font-weight: 600;
  font-size: $movie-card-font-medium;
}

.MovieCard__earnings-value {
  position: relative;

  .is-void & {
    color: rgba(white, .5);

    &::after {
      background: white;
      content: '';
      display: block;
      height: 2px;
      left: -2px;
      position: absolute;
      right: -2px;
      top: 50%;
    }
  }
}

.MovieCard__secondary-result,
.MovieCard__remove-movie {
  font-size: $movie-card-font-small;
  font-weight: 300;
  line-height: $movie-card-line-height-medium;
  border-top: 1px solid $page-color;
}

.MovieCard__secondary-result {
  background: $primary-color--xxxlight;
  border-radius: 3px;
  color: $primary-color;
}

.MovieCard__remove-movie {
  color: $accent-color;
}

.MovieCard__secondary-result-no-data {
  font-size: $movie-card-font-tiny;
}

.MovieCard__bonus {
  margin: $xtight-spacing 0;
}

// TOP FIVE GAME (DEPRECATED)

.MovieCard__history-numbers {
  font-size: $movie-card-font-tiny;
  height: $movie-card-line-height-medium;
  line-height: $movie-card-line-height-medium;
  border-top: 1px solid $page-color;
}

.MovieCard__history {
  font-weight: 600;
  text-transform: uppercase;
}

.MovieCard__history-date {
  float: left;
  margin-left: .625rem;
  width: 25%;
}

.MovieCard__history-change {
  float: left;
  font-weight: 100;
  text-align: right;
  width: 25%;
}

.MovieCard__history-earnings {
  float: right;
  margin-right: .625rem;
}

.MovieCard__averages {
  border-top: 1px solid $page-color;
}

// TODO: See if these can be combined
.MovieCard__topfive--average {
  background-color: $primary-color--xxlight;
  border-radius: 3px 3px 0 0;
  font-weight: 600;
  margin-top: 1.25rem;
}

.MovieCard__topfive--average,
.MovieCard__topfive--high,
.MovieCard__topfive--low,
.MovieCard__topfive--median {
  font-size: $movie-card-font-tiny;
  height: $movie-card-line-height-medium;
  line-height: $movie-card-line-height-medium;
}

.MovieCard__topfive--high,
.MovieCard__topfive--low,
.MovieCard__topfive--median {
  background-color: $alternate-color--xxxlight;
  font-weight: 300;
}

.MovieCard__topfive-label {
  float: left;
  margin-left: .625rem;
}

.MovieCard__topfive-figure {
  float: right;
  margin-right: .625rem;
}
