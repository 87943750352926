.EmptyState {
  @include type-spacing($type-spacing--loose);
  align-items: center;
  border-top: $boc-border--thin solid $base-color--xxlight;
  border-bottom: $boc-border--thin solid $base-color--xxlight;
  color: $base-color--xlight;
  display: flex;
  justify-content: center;
  min-height: 10em;
  padding: $panel-spacing;
  text-align: center;
  width: 100%;

  &__title {
    @include responsive-font(large);
    font-weight: $font-light;
  }
}
