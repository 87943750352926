.TeamHeader {
  align-items: center;
  color: $primary-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @include at-tablet-width {
    flex-direction: row;
  }
}

.TeamHeader__avatar {
  @extend %Avatar--large;
  flex: 0 0 auto;
}

.TeamHeader__name {
  @include responsive-font(xxxlarge);
  flex: 0 1 auto;
  font-weight: $font-bold;
  margin: .2em 0 0;
  min-width: 0; // fixes sizing bug
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include at-tablet-width {
    margin: 0 0 0 .5em;
  }

  @include below-tablet-width {
    font-size: responsive-font-size(phone, xxlarge);
    line-height: responsive-line-height(phone, xxlarge);
  }
}
