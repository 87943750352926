%TextSelect {
  appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-size: 1em;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0 1.3em 0 0;
}

.TextSelect--primary {
  @extend %TextSelect;
  @include background-icon(chevron-down, $primary-color);
}
