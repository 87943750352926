$movie-stat-cell-spacing: 8px;

.MovieStats {
  @include responsive-font(small, 2);
  color: $alternate-color--xdark;
  display: table;
  text-align: center;
  white-space: nowrap;
  width: 100%;

  &:first-child {
    margin-top: 10px;
  }

  &__label {
    background-color: $alternate-color--light;
    border-radius: 3px 3px 0 0;
    display: table-caption;
    font-weight: $font-bold;
    padding: 0 $movie-stat-cell-spacing;
  }

  &__stats {
    display: table-row;
    table-layout: fixed;
    width: 100%;

    &:first-child {

      > *:first-child {
        border-radius: 3px 0 0;
      }

      > *:last-child {
        border-radius: 0 3px 0 0;
      }
    }

    &:last-child {

      > *:first-child {
        border-radius: 0 0 0 3px;
      }

      > *:last-child {
        border-radius: 0 0 3px;
      }
    }

    > * {
      background-color: $alternate-color--xxxlight;
      border-top: 1px solid #fff;
      display: table-cell;
      padding: 0 $movie-stat-cell-spacing 0 0;
    }

    > *:first-child {
      padding-left: $movie-stat-cell-spacing;
    }

    > *:not(:only-child) {
      text-align: left;
    }

    > *:first-child:not(:only-child) {
      text-align: left;
      width: 1px;
    }

    > *:last-child:not(:only-child) {
      text-align: right;
    }
  }

  &__stat--small {
    @include at-desktop-width {
      width: 2.5em;
    }
  }

  &__stat--alternate {
    font-weight: $font-bold;
  }

  &__stat--secondary {
    font-weight: $font-light;
  }
}
