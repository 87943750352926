$icon-spacing: .25em;

%Icon {
  display: inline-block;
  fill: currentColor;
  transform: scale(1.4);
  vertical-align: baseline;
}

%Icon--normal {
  height: 1em;
  margin-bottom: -.15em;
  width: 1em;
}

%Icon--small {
  @extend %Icon;
  height: .7em;
  width: .7em;
  margin-bottom: 0;
}

.Icon {
  @extend %Icon;
  @extend %Icon--normal;

  &--right {
    @extend %Icon;
    margin-left: $icon-spacing;
  }

  &--left {
    @extend %Icon;
    margin-right: $icon-spacing;
  }
}

.Icon--alert {
  @extend %Icon;
  @extend %Icon--normal;
  color: $accent-color;
}

.Icon--small {
  @extend %Icon--small;

  &-right {
    @extend %Icon--small;
    margin-left: $icon-spacing;
  }

  &-left {
    @extend %Icon--small;
    margin-right: $icon-spacing;
  }
}

// BOC Abbreviated Logo

.BocAbbrev {
  fill: currentColor;
  height: 20px;
  width: 58px;
}
