.BocFooter:not(x) { // for specificity
  @include responsive-font(normal);
  padding-bottom: 3rem;
  background: $primary-color--xlight;
  color: $primary-color--light;
  border-top: 3rem solid $page-color;

  @include at-desktop-width {
    padding-bottom: 5rem;
  }

}

.BocFooter__section-title {
  color: $primary-color;
  font-weight: $font-bold;
  text-transform: uppercase;
  margin-bottom: .8em;
}

.BocFooter__link {
  display: block;
  color: $primary-color--light;
  margin: .5em 0;

  &:hover {
    color: $primary-color;
  }
}

.BocFooter__line {
  display: block;
  color: $primary-color--light;
  margin: .5em 0;

  a:hover {
    color: $primary-color;
  }
}

.BocFooter__social-link {
  display: inline-block;
  fill: $primary-color--light;
  margin: .2em 0;

  + .BocFooter__social-link { margin-left: .4em; }

  &:hover {
    fill: $primary-color;
  }
}

.BocSocialIcon {
  display: inline-block;
  width: 2em;
  height: 2em;
}

.BocFooter__legal {
  @include below-tablet-width {
    text-align: center;
    > * {
      display: inline-block;
      margin: 0 1rem 0 0;
    }
  }
}
