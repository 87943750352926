@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin overflow-scrolling($val: touch) {
  #{'-webkit-overflow-scrolling'}: $val;
}

@mixin background-icon($name, $color: black) {
  @if $name == 'chevron-down' { background-image: url('data:image/svg+xml;utf8,<svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill="#{$color}" d="M8,12.2 L14,6.152 L12.8571429,5 L8,9.896 L3.14285714,5 L2,6.152 L8,12.2 L8,12.2 Z"></path></svg>'); }
}
