.FileField {
  display: block;

  input {
    position: absolute;
    top: -999in;
    left: -999in;
    width: 0;
    height: 0;
  }
}

.FileField__label {
  @include responsive-font(small);
  color: $accent-color;
}
