%boc-calendar--link {
  color: $accent-color;
  display: block;
  flex: 0 0 auto;

  &:hover { color: boc-accent-color--dark; }

  @include at-tablet-width {
    flex: 0 0 30%;
  }
}

.BocCalendar {

  &__nav {
    align-items: center;
    background: $page-color;
    display: flex;
    min-height: 3rem;

    &.tether-pinned::after {
      background: $accent-color;
      bottom: -1px;
      content: '';
      height: 1px;
      left: -$page-margin-phone;
      position: absolute;
      width: 100vw;

      @include at-tablet-width { left: -$page-margin; }
      @include at-page-width { left: calc(#{$page-width / 2 - $page-margin} - 50vw); }
    }
  }

  &__label {
    @include responsive-font(large, 1);
    flex: 10 1 100%;
    text-align: center;
  }

  &__prev {
    @extend %boc-calendar--link;
    text-align: left;
  }

  &__next {
    @extend %boc-calendar--link;
    text-align: right;
  }

  &__link-icon {
    width: 2em;
    height: 2em;
    vertical-align: -45%;
  }

  &__link-text {
    @include below-tablet-width {
      display: none;
    }
  }
}
