@charset "UTF-8";
* {
  background: none;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  list-style: none;
  margin: 0;
  padding: 0; }

svg:not([full-color]) {
  fill: currentColor; }

html {
  background: #8bc0ca;
  color: #3c3c3d;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2; }
  html.has-no-nav {
    background: #fff; }
  @media (min-width: 650px) {
    html {
      line-height: 1.4; } }
  @media (min-width: 950px) {
    html {
      font-size: 20px; } }

a {
  text-decoration: none; }

b {
  font-weight: 600; }

i {
  font-style: italic; }

button {
  font: inherit; }

[boc-toggle] {
  cursor: pointer; }


.PageTitle {
  color: #168095;
  font-weight: 300;
  margin-bottom: 1.5rem; }
  @media (min-width: 0) {
    
    .PageTitle {
      font-size: 36px;
      line-height: 42px; } }
  @media (min-width: 950px) {
    
    .PageTitle {
      font-size: 48px;
      line-height: 58px; } }
  
  .PageTitle--alternate {
    color: #3c3c3d; }


.PageTitle--large {
  color: #168095;
  font-weight: 300;
  margin-bottom: 1.5rem; }
  @media (min-width: 0) {
    
    .PageTitle--large {
      font-size: 48px;
      line-height: 58px; } }
  @media (min-width: 950px) {
    
    .PageTitle--large {
      font-size: 64px;
      line-height: 64px; } }
  
  .PageTitle--large--alternate {
    color: #3c3c3d; }

.MoviePopup__title, .MoviePopup__edit-title,
.PageTitle--small {
  color: #168095;
  font-weight: 300;
  margin-bottom: 1.5rem; }
  @media (min-width: 0) {
    .MoviePopup__title, .MoviePopup__edit-title,
    .PageTitle--small {
      font-size: 24px;
      line-height: 32px; } }
  @media (min-width: 950px) {
    .MoviePopup__title, .MoviePopup__edit-title,
    .PageTitle--small {
      font-size: 36px;
      line-height: 42px; } }


.PageSubtitle {
  color: #9e9e9e;
  font-weight: 300;
  margin-bottom: 1.5rem; }
  @media (min-width: 0) {
    
    .PageSubtitle {
      font-size: 24px;
      line-height: 32px; } }
  @media (min-width: 950px) {
    
    .PageSubtitle {
      font-size: 36px;
      line-height: 42px; } }
  .PageTitle +
  .PageSubtitle {
    margin-top: -1.125rem; }

.BocTabs__header,
.SectionTitle {
  border-bottom: 0.125rem solid #168095;
  color: #3c3c3d;
  font-weight: 400;
  padding-bottom: .4em;
  text-transform: uppercase; }
  @media (min-width: 0) {
    .BocTabs__header,
    .SectionTitle {
      font-size: 21px;
      line-height: 28px; } }
  @media (min-width: 950px) {
    .BocTabs__header,
    .SectionTitle {
      font-size: 28px;
      line-height: 34px; } }
  .BocTabs__header:not(:last-child),
  .SectionTitle:not(:last-child) {
    margin-bottom: .9em; }


.Heading {
  color: #3c3c3d;
  font-weight: 600; }
  @media (min-width: 0) {
    
    .Heading {
      font-size: 21px;
      line-height: 28px; } }
  @media (min-width: 950px) {
    
    .Heading {
      font-size: 24px;
      line-height: 32px; } }

.Subheading, .Subheading--small {
  color: #168095;
  font-weight: 600;
  text-transform: uppercase; }

@media (min-width: 0) {
  .Subheading {
    font-size: 16px;
    line-height: 24px; } }

@media (min-width: 950px) {
  .Subheading {
    font-size: 18px;
    line-height: 24px; } }

@media (min-width: 0) {
  .Subheading--small {
    font-size: 12px;
    line-height: 16px; } }

@media (min-width: 950px) {
  .Subheading--small {
    font-size: 14px;
    line-height: 18px; } }

@media (min-width: 0) {
  .List, .Example,
  .Text {
    font-size: 16px;
    line-height: 24px; } }

@media (min-width: 950px) {
  .List, .Example,
  .Text {
    font-size: 18px;
    line-height: 24px; } }

.List:not(:last-child), .Example:not(:last-child),
.Text:not(:last-child) {
  margin-bottom: 20px; }

.Text--large {
  font-weight: 300; }
  @media (min-width: 0) {
    .Text--large {
      font-size: 21px;
      line-height: 28px; } }
  @media (min-width: 950px) {
    .Text--large {
      font-size: 24px;
      line-height: 32px; } }
  .Text--large:not(:last-child) {
    margin-bottom: 20px; }

@media (min-width: 0) {
  .Text--small {
    font-size: 12px;
    line-height: 16px; } }

@media (min-width: 950px) {
  .Text--small {
    font-size: 14px;
    line-height: 18px; } }

.Text--small:not(:last-child) {
  margin-bottom: 20px; }

@media (min-width: 0) {
  .Text--xsmall {
    font-size: 11px;
    line-height: 14px; } }

@media (min-width: 950px) {
  .Text--xsmall {
    font-size: 12px;
    line-height: 16px; } }

.Text--xsmall:not(:last-child) {
  margin-bottom: 20px; }

.HelpText {
  display: block;
  font-style: italic;
  font-weight: 300; }
  @media (min-width: 0) {
    .HelpText {
      font-size: 12px;
      line-height: 16px; } }
  @media (min-width: 950px) {
    .HelpText {
      font-size: 14px;
      line-height: 18px; } }

.List {
  padding-left: 2em; }
  .List > li {
    list-style: inherit;
    margin: .4em 0; }

ul.List {
  list-style: disc; }

ol.List {
  list-style: decimal; }

.Link {
  color: #d84d4f;
  cursor: pointer;
  text-decoration: none; }
  .Link:hover {
    color: #b94a4b;
    text-decoration: underline; }

.Example {
  border-left: 4px solid #d8d8d8;
  padding-left: 1em; }

.Highlight {
  color: #168095;
  font-style: normal; }

.Warning {
  color: #d84d4f; }

keep-together,
.KeepTogether {
  display: inline-block;
  vertical-align: baseline; }

[page-section],
.PageSection, .Overlay__header, .Overlay__content {
  background-color: #fff;
  display: block;
  box-sizing: border-box;
  padding: 30px 16px;
  position: relative;
  width: 100%; }
  @media (min-width: 650px) {
    [page-section],
    .PageSection, .Overlay__header, .Overlay__content {
      padding: 30px; } }
  @media (min-width: 1024px) {
    [page-section],
    .PageSection, .Overlay__header, .Overlay__content {
      padding: 30px calc(50% - 482px); } }

[grid] {
  box-sizing: content-box !important;
  display: flex;
  flex-direction: row;
  flex-grow: 100;
  flex-shrink: 1;
  flex-wrap: wrap;
  position: relative; }
  [grid] > * {
    box-sizing: border-box; }

[grid~='nowrap'] {
  flex-wrap: nowrap; }

[grid~='align-middle'] {
  align-items: center; }

[grid~='align-bottom'] {
  align-items: flex-end; }

[grid~='align-baseline'] {
  align-items: baseline; }

[grid~='align-left'] {
  justify-content: flex-start; }

[grid~='align-center'] {
  justify-content: center; }

[grid~='align-right'] {
  justify-content: flex-end; }

[grid~='spaced'] {
  justify-content: space-between; }

[grid-el] {
  display: block;
  flex-basis: 0;
  min-width: 0; }

[grid~='fixed'] [grid-el] {
  flex-grow: 0; }

[grid~='loose'] {
  margin: -1.5rem 0 0 -1.5rem; }
  [grid~='loose']:not(:first-child) {
    margin-top: 0; }
  [grid~='loose'] > * {
    display: block;
    padding: 1.5rem 0 0 1.5rem; }

[grid~='tight'] {
  margin: -1rem 0 0 -1rem; }
  [grid~='tight']:not(:first-child) {
    margin-top: 0; }
  [grid~='tight'] > * {
    display: block;
    padding: 1rem 0 0 1rem; }

@media (min-width: 0) {
  [grid~='first@phone'] {
    order: -2; }
  [grid~='higher@phone'] {
    order: -1; }
  [grid~='lower@phone'] {
    order: 1; }
  [grid~='last@phone'] {
    order: 2; }
  [grid] > [grid~='shrink@phone'] {
    flex: 0 0 auto; }
  [grid] > [grid~='grow@phone'] {
    flex: 100 100 auto; }
  [grid] > [grid~='1/1@phone'] {
    flex-basis: 100%;
    min-width: 0; }
  [grid] > [grid~='1/6@phone'] {
    flex-basis: 16.666%;
    min-width: 0; }
  [grid] > [grid~='1/5@phone'] {
    flex-basis: 20%;
    min-width: 0; }
  [grid] > [grid~='1/4@phone'] {
    flex-basis: 25%;
    min-width: 0; }
  [grid] > [grid~='1/3@phone'] {
    flex-basis: 33.333%;
    min-width: 0; }
  [grid] > [grid~='1/2@phone'] {
    flex-basis: 50%;
    min-width: 0; }
  [grid] > [grid~='2/3@phone'] {
    flex-basis: 66.666%;
    min-width: 0; }
  [grid] > [grid~='3/4@phone'] {
    flex-basis: 75%;
    min-width: 0; }
  [grid~="loose"] > [grid~='shrink@phone'] {
    flex: 0 0 auto; }
  [grid~="loose"] > [grid~='grow@phone'] {
    flex: 100 100 auto; }
  [grid~="loose"] > [grid~='1/1@phone'] {
    flex-basis: calc(100% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/6@phone'] {
    flex-basis: calc(16.666% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/5@phone'] {
    flex-basis: calc(20% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/4@phone'] {
    flex-basis: calc(25% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/3@phone'] {
    flex-basis: calc(33.333% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/2@phone'] {
    flex-basis: calc(50% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='2/3@phone'] {
    flex-basis: calc(66.666% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='3/4@phone'] {
    flex-basis: calc(75% - 1.5rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='shrink@phone'] {
    flex: 0 0 auto; }
  [grid~="tight"] > [grid~='grow@phone'] {
    flex: 100 100 auto; }
  [grid~="tight"] > [grid~='1/1@phone'] {
    flex-basis: calc(100% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/6@phone'] {
    flex-basis: calc(16.666% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/5@phone'] {
    flex-basis: calc(20% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/4@phone'] {
    flex-basis: calc(25% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/3@phone'] {
    flex-basis: calc(33.333% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/2@phone'] {
    flex-basis: calc(50% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='2/3@phone'] {
    flex-basis: calc(66.666% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='3/4@phone'] {
    flex-basis: calc(75% - 1rem);
    min-width: 0; } }

@media (min-width: 0) and (max-width: 649px) {
  .hide-only\@phone,
  [grid~='hide-only@phone'] {
    display: none !important; } }

@media (max-width: -1) {
  .show-only\@phone,
  [grid~='show-only@phone'] {
    display: none !important; } }

@media (min-width: 650px) {
  .show-only\@phone,
  [grid~='show-only@phone'] {
    display: none !important; } }

@media (min-width: 0) {
  .hide\@phone,
  [grid~='hide@phone'] {
    display: none !important; } }

@media (max-width: -1) {
  .show\@phone,
  [grid~='show@phone'] {
    display: none !important; } }

@media (min-width: 650px) {
  [grid~='first@tablet'] {
    order: -2; }
  [grid~='higher@tablet'] {
    order: -1; }
  [grid~='lower@tablet'] {
    order: 1; }
  [grid~='last@tablet'] {
    order: 2; }
  [grid] > [grid~='shrink@tablet'] {
    flex: 0 0 auto; }
  [grid] > [grid~='grow@tablet'] {
    flex: 100 100 auto; }
  [grid] > [grid~='1/1@tablet'] {
    flex-basis: 100%;
    min-width: 0; }
  [grid] > [grid~='1/6@tablet'] {
    flex-basis: 16.666%;
    min-width: 0; }
  [grid] > [grid~='1/5@tablet'] {
    flex-basis: 20%;
    min-width: 0; }
  [grid] > [grid~='1/4@tablet'] {
    flex-basis: 25%;
    min-width: 0; }
  [grid] > [grid~='1/3@tablet'] {
    flex-basis: 33.333%;
    min-width: 0; }
  [grid] > [grid~='1/2@tablet'] {
    flex-basis: 50%;
    min-width: 0; }
  [grid] > [grid~='2/3@tablet'] {
    flex-basis: 66.666%;
    min-width: 0; }
  [grid] > [grid~='3/4@tablet'] {
    flex-basis: 75%;
    min-width: 0; }
  [grid~="loose"] > [grid~='shrink@tablet'] {
    flex: 0 0 auto; }
  [grid~="loose"] > [grid~='grow@tablet'] {
    flex: 100 100 auto; }
  [grid~="loose"] > [grid~='1/1@tablet'] {
    flex-basis: calc(100% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/6@tablet'] {
    flex-basis: calc(16.666% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/5@tablet'] {
    flex-basis: calc(20% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/4@tablet'] {
    flex-basis: calc(25% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/3@tablet'] {
    flex-basis: calc(33.333% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/2@tablet'] {
    flex-basis: calc(50% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='2/3@tablet'] {
    flex-basis: calc(66.666% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='3/4@tablet'] {
    flex-basis: calc(75% - 1.5rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='shrink@tablet'] {
    flex: 0 0 auto; }
  [grid~="tight"] > [grid~='grow@tablet'] {
    flex: 100 100 auto; }
  [grid~="tight"] > [grid~='1/1@tablet'] {
    flex-basis: calc(100% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/6@tablet'] {
    flex-basis: calc(16.666% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/5@tablet'] {
    flex-basis: calc(20% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/4@tablet'] {
    flex-basis: calc(25% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/3@tablet'] {
    flex-basis: calc(33.333% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/2@tablet'] {
    flex-basis: calc(50% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='2/3@tablet'] {
    flex-basis: calc(66.666% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='3/4@tablet'] {
    flex-basis: calc(75% - 1rem);
    min-width: 0; } }

@media (min-width: 650px) and (max-width: 949px) {
  .hide-only\@tablet,
  [grid~='hide-only@tablet'] {
    display: none !important; } }

@media (max-width: 649px) {
  .show-only\@tablet,
  [grid~='show-only@tablet'] {
    display: none !important; } }

@media (min-width: 950px) {
  .show-only\@tablet,
  [grid~='show-only@tablet'] {
    display: none !important; } }

@media (min-width: 650px) {
  .hide\@tablet,
  [grid~='hide@tablet'] {
    display: none !important; } }

@media (max-width: 649px) {
  .show\@tablet,
  [grid~='show@tablet'] {
    display: none !important; } }

@media (min-width: 950px) {
  [grid~='first@desktop'] {
    order: -2; }
  [grid~='higher@desktop'] {
    order: -1; }
  [grid~='lower@desktop'] {
    order: 1; }
  [grid~='last@desktop'] {
    order: 2; }
  [grid] > [grid~='shrink@desktop'] {
    flex: 0 0 auto; }
  [grid] > [grid~='grow@desktop'] {
    flex: 100 100 auto; }
  [grid] > [grid~='1/1@desktop'] {
    flex-basis: 100%;
    min-width: 0; }
  [grid] > [grid~='1/6@desktop'] {
    flex-basis: 16.666%;
    min-width: 0; }
  [grid] > [grid~='1/5@desktop'] {
    flex-basis: 20%;
    min-width: 0; }
  [grid] > [grid~='1/4@desktop'] {
    flex-basis: 25%;
    min-width: 0; }
  [grid] > [grid~='1/3@desktop'] {
    flex-basis: 33.333%;
    min-width: 0; }
  [grid] > [grid~='1/2@desktop'] {
    flex-basis: 50%;
    min-width: 0; }
  [grid] > [grid~='2/3@desktop'] {
    flex-basis: 66.666%;
    min-width: 0; }
  [grid] > [grid~='3/4@desktop'] {
    flex-basis: 75%;
    min-width: 0; }
  [grid~="loose"] > [grid~='shrink@desktop'] {
    flex: 0 0 auto; }
  [grid~="loose"] > [grid~='grow@desktop'] {
    flex: 100 100 auto; }
  [grid~="loose"] > [grid~='1/1@desktop'] {
    flex-basis: calc(100% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/6@desktop'] {
    flex-basis: calc(16.666% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/5@desktop'] {
    flex-basis: calc(20% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/4@desktop'] {
    flex-basis: calc(25% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/3@desktop'] {
    flex-basis: calc(33.333% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='1/2@desktop'] {
    flex-basis: calc(50% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='2/3@desktop'] {
    flex-basis: calc(66.666% - 1.5rem);
    min-width: 0; }
  [grid~="loose"] > [grid~='3/4@desktop'] {
    flex-basis: calc(75% - 1.5rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='shrink@desktop'] {
    flex: 0 0 auto; }
  [grid~="tight"] > [grid~='grow@desktop'] {
    flex: 100 100 auto; }
  [grid~="tight"] > [grid~='1/1@desktop'] {
    flex-basis: calc(100% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/6@desktop'] {
    flex-basis: calc(16.666% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/5@desktop'] {
    flex-basis: calc(20% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/4@desktop'] {
    flex-basis: calc(25% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/3@desktop'] {
    flex-basis: calc(33.333% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='1/2@desktop'] {
    flex-basis: calc(50% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='2/3@desktop'] {
    flex-basis: calc(66.666% - 1rem);
    min-width: 0; }
  [grid~="tight"] > [grid~='3/4@desktop'] {
    flex-basis: calc(75% - 1rem);
    min-width: 0; } }

@media (min-width: 950px) {
  .hide-only\@desktop,
  [grid~='hide-only@desktop'] {
    display: none !important; } }

@media (max-width: 949px) {
  .show-only\@desktop,
  [grid~='show-only@desktop'] {
    display: none !important; } }

@media (min-width: 950px) {
  .hide\@desktop,
  [grid~='hide@desktop'] {
    display: none !important; } }

@media (max-width: 949px) {
  .show\@desktop,
  [grid~='show@desktop'] {
    display: none !important; } }

.AlertScreen {
  text-align: center; }
  .AlertScreen .GameIcon--large {
    margin-bottom: .9375rem; }

.AlertScreen__secondary-message {
  font-size: 1.5rem;
  font-weight: 300; }

.AlertScreen__info-graphic {
  margin: 0 auto;
  width: 45rem;
  text-align: center; }
  .AlertScreen__info-graphic img {
    width: 100%; }

.Avatar,
.Avatar--nav,
.GameIcon--small,
.DataTable__avatar,
.Avatar--small,
.GameIcon--medium,
.TeamSummary__avatar,
.Avatar--medium,
.GameIcon--large,
.TeamHeader__avatar,
.Avatar--large,
.GameCard__avatar {
  align-items: center;
  background-color: #8bc0ca;
  background-size: cover;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  font-weight: 400;
  justify-content: center;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  z-index: 0; }
  .Avatar[alt]::after,
  [alt].Avatar--nav::after,
  [alt].GameIcon--small::after,
  [alt].DataTable__avatar::after,
  [alt].Avatar--small::after,
  [alt].GameIcon--medium::after,
  [alt].TeamSummary__avatar::after,
  [alt].Avatar--medium::after,
  [alt].GameIcon--large::after,
  [alt].TeamHeader__avatar::after,
  [alt].Avatar--large::after,
  [alt].GameCard__avatar::after {
    content: attr(alt);
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap; }

.Avatar--nav {
  font-size: 0.72em;
  width: 2.4em;
  height: 2.4em; }

.GameIcon--small, .DataTable__avatar,
.Avatar--small {
  font-size: 12px;
  width: 40px;
  height: 40px; }

.GameIcon--medium, .TeamSummary__avatar,
.Avatar--medium {
  font-size: 12px;
  width: 40px;
  height: 40px; }
  @media (min-width: 950px) {
    .GameIcon--medium, .TeamSummary__avatar,
    .Avatar--medium {
      font-size: 18px;
      width: 60px;
      height: 60px; } }

.GameIcon--large, .TeamHeader__avatar,
.Avatar--large {
  font-size: 18px;
  width: 60px;
  height: 60px; }
  @media (min-width: 950px) {
    .GameIcon--large, .TeamHeader__avatar,
    .Avatar--large {
      font-size: 30px;
      width: 100px;
      height: 100px; } }

#BlogPostHeroImage {
  background-size: cover;
  color: #fff;
  height: 500px;
  position: relative;
  width: 100%; }

#BlogPostHeroShadow {
  bottom: 0;
  top: 50%;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
  background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
  /* IE6-9 */ }

#BlogPostHeroContent {
  bottom: 30px;
  position: absolute;
  text-align: center;
  z-index: 2;
  width: 100%; }

#BlogPostHeadline {
  font-size: 64px;
  font-weight: 100;
  line-height: 64px; }

#BlogPostType {
  color: #FFCC33;
  font-weight: 600;
  text-transform: uppercase; }

.BlogPostByline_Avatar {
  background-color: #8BC0CA;
  border-radius: 100%;
  float: left;
  height: 60px;
  width: 60px; }

.BlogPostByLine_Author {
  font-weight: 400;
  margin-left: 70px; }

.BlogPostByLine_TimeStamp {
  font-weight: 200;
  margin-left: 70px; }

#BlogPostContent {
  font-size: 18px;
  line-height: 24px;
  font-weight: 200; }

#BlogPostContent_Editorial em {
  font-style: italic; }

#BlogPostContent_Editorial strong {
  font-weight: 600; }

#BlogPostContent_WeekendTop10-Title {
  background-color: #8BC0CA;
  border-radius: 4px 4px 0 0;
  color: #FFFFFF;
  height: 50px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase; }

.Top10-Listing {
  display: block;
  overflow: hidden;
  padding: 10px 0;
  border-bottom: solid #D8D8D9 1px; }

.Top10_Numbers {
  float: left;
  width: 25%; }

.Top10_WeekendNumber {
  color: #168095;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  text-align: right; }

.Top10_MovieTitle {
  float: right;
  width: 75%;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  padding-left: 10px; }

/* Add styles for strong and em for blog posts */
.BlogListPost {
  max-width: 960px;
  margin-bottom: 30px;
  border-bottom: solid 5px #168095; }

.BlogListPost .BlogPostByline {
  margin-bottom: 30px; }

.BlogListPostImage {
  margin-bottom: 20px;
  height: 482px;
  display: block;
  background-position: center;
  background-size: cover; }

.BlogListPostImage img {
  width: 100%; }

.BlogListPostType {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase; }

.BlogListPostTitle {
  font-size: 48px;
  font-weight: 200;
  line-height: 58px;
  margin-bottom: 20px; }

.BlogListPagination {
  text-align: center; }

.BlogListPagination span {
  padding: 0 16px;
  display: inline-block; }

.BlogListPaginationCurrent {
  background-color: #168095;
  color: white;
  border-radius: 100%;
  line-height: 40px;
  height: 40px; }

.Button--full, .Button--primary-full, .Button--primary-notification, .Button--large-full, .Button--primary-large-full, .Button--small-full, .Button--primary-small-full, .Button--primary, .Button--primary-large, .Button--primary-small, .MovieCard__select, .Button--large, .Button--small, .Button--small-secondary, .Button {
  display: inline-block;
  padding: 1em;
  border-radius: .2em;
  background-color: #168095;
  transition: background-color 300ms;
  color: #fff;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  appearance: none; }
  @media (min-width: 0) {
    .Button--full, .Button--primary-full, .Button--primary-notification, .Button--large-full, .Button--primary-large-full, .Button--small-full, .Button--primary-small-full, .Button--primary, .Button--primary-large, .Button--primary-small, .MovieCard__select, .Button--large, .Button--small, .Button--small-secondary, .Button {
      font-size: 16px;
      line-height: 1; } }
  @media (min-width: 950px) {
    .Button--full, .Button--primary-full, .Button--primary-notification, .Button--large-full, .Button--primary-large-full, .Button--small-full, .Button--primary-small-full, .Button--primary, .Button--primary-large, .Button--primary-small, .MovieCard__select, .Button--large, .Button--small, .Button--small-secondary, .Button {
      font-size: 18px;
      line-height: 1; } }
  .Button--full:hover, .Button--primary-full:hover, .Button--primary-notification:hover, .Button--large-full:hover, .Button--primary-large-full:hover, .Button--small-full:hover, .Button--primary-small-full:hover, .Button--primary:hover, .Button--primary-large:hover, .Button--primary-small:hover, .MovieCard__select:hover, .Button--large:hover, .Button--small:hover, .Button--small-secondary:hover, .Button:hover {
    background-color: #1e7283; }

.Button--full, .Button--primary-full, .Button--primary-notification, .Button--large-full, .Button--primary-large-full, .Button--small-full, .Button--primary-small-full {
  width: 100%; }

.Button--primary, .Button--primary-full, .Button--primary-notification, .Button--primary-large, .Button--primary-large-full, .Button--primary-small, .Button--primary-small-full, .MovieCard__select {
  background-color: #d84d4f; }
  .Button--primary:hover, .Button--primary-full:hover, .Button--primary-notification:hover, .Button--primary-large:hover, .Button--primary-large-full:hover, .Button--primary-small:hover, .Button--primary-small-full:hover, .MovieCard__select:hover {
    background-color: #b94a4b; }

.Button--primary-notification {
  text-transform: none;
  padding: 1.125rem;
  border-radius: 0.25rem; }

@media (min-width: 0) {
  .Button--large, .Button--large-full, .Button--primary-large, .Button--primary-large-full, .MovieCard__select {
    font-size: 21px;
    line-height: 1; } }

@media (min-width: 950px) {
  .Button--large, .Button--large-full, .Button--primary-large, .Button--primary-large-full, .MovieCard__select {
    font-size: 24px;
    line-height: 1; } }

@media (min-width: 0) {
  .Button--small, .Button--small-full, .Button--primary-small, .Button--primary-small-full, .Button--small-secondary {
    font-size: 12px;
    line-height: 1; } }

@media (min-width: 950px) {
  .Button--small, .Button--small-full, .Button--primary-small, .Button--primary-small-full, .Button--small-secondary {
    font-size: 14px;
    line-height: 1; } }

.Button--small-secondary {
  background: #fff;
  border: 2px solid #168095;
  color: #168095;
  padding: calc(1em - 2px); }
  .Button--small-secondary:hover {
    background-color: #fbfaf9; }

.ButtonBar {
  display: flex;
  flex-wrap: nowrap;
  width: 100%; }
  .ButtonBar > * {
    flex: 1 1 50%; }
    .ButtonBar > *:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-left: 0; }
    .ButtonBar > *:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 0; }

.BocCalendar__prev, .BocCalendar__next {
  color: #d84d4f;
  display: block;
  flex: 0 0 auto; }
  .BocCalendar__prev:hover, .BocCalendar__next:hover {
    color: boc-accent-color--dark; }
  @media (min-width: 650px) {
    .BocCalendar__prev, .BocCalendar__next {
      flex: 0 0 30%; } }

.BocCalendar__nav {
  align-items: center;
  background: #fff;
  display: flex;
  min-height: 3rem; }
  .BocCalendar__nav.tether-pinned::after {
    background: #d84d4f;
    bottom: -1px;
    content: '';
    height: 1px;
    left: -16px;
    position: absolute;
    width: 100vw; }
    @media (min-width: 650px) {
      .BocCalendar__nav.tether-pinned::after {
        left: -30px; } }
    @media (min-width: 1024px) {
      .BocCalendar__nav.tether-pinned::after {
        left: calc(482px - 50vw); } }

.BocCalendar__label {
  flex: 10 1 100%;
  text-align: center; }
  @media (min-width: 0) {
    .BocCalendar__label {
      font-size: 21px;
      line-height: 1; } }
  @media (min-width: 950px) {
    .BocCalendar__label {
      font-size: 24px;
      line-height: 1; } }

.BocCalendar__prev {
  text-align: left; }

.BocCalendar__next {
  text-align: right; }

.BocCalendar__link-icon {
  width: 2em;
  height: 2em;
  vertical-align: -45%; }

@media (max-width: 649px) {
  .BocCalendar__link-text {
    display: none; } }

.Chatter__footer, .ChatterPost {
  padding: 15px;
  width: 100%; }
  @media (min-width: 600px) {
    .Chatter__footer, .ChatterPost {
      padding: 15px calc(50% - 300px); } }

.Chatter {
  background: #fff;
  bottom: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  height: 3.6em;
  position: fixed;
  transition: height 500ms ease-in-out;
  width: 100%;
  z-index: 11; }
  .Chatter.is-active {
    height: 100vh; }
  .Chatter::after {
    content: '';
    display: block;
    height: 0;
    outline: 3.6em solid #fff;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: -1; }
  .Chatter__header {
    align-items: center;
    box-shadow: 0 1px 0 #168095;
    cursor: pointer;
    display: block;
    flex: 0 0 auto;
    font-size: 1em;
    line-height: 1;
    min-height: 3.6em;
    padding: 0.75em;
    position: relative;
    text-align: center;
    z-index: 2; }
  .Chatter__header-count {
    align-items: center;
    background: url("/img/icons/chatter-bubble.svg") center center no-repeat;
    background-size: 3.6em;
    color: #fff;
    display: flex;
    height: 3.6em;
    justify-content: center;
    left: 0;
    padding: 0 .7em .7em 0;
    position: absolute;
    top: -0.75em;
    width: 3.6em; }
  .Chatter__header-title {
    font-size: .9em;
    height: .9em;
    margin-bottom: .2em; }
  .Chatter__header-link {
    color: #d84d4f;
    display: block;
    font-size: .8em; }
  .Chatter__room-title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #168095;
    font-weight: 300;
    height: 0;
    line-height: 1.4em;
    transition: height 500ms ease-in-out; }
    @media (min-width: 0) {
      .Chatter__room-title {
        font-size: 36px;
        line-height: 42px; } }
    @media (min-width: 950px) {
      .Chatter__room-title {
        font-size: 48px;
        line-height: 58px; } }
    .Chatter.is-active .Chatter__room-title {
      height: 1.4em;
      transition: height 500ms ease-in-out; }
  .Chatter__banner {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    min-height: 2.1em;
    padding-left: 4.2em;
    position: relative;
    text-align: left; }
  .Chatter__posts {
    align-items: flex-start;
    display: flex;
    flex: 100 100 0%;
    flex-direction: column-reverse;
    height: 0;
    overflow: auto; }
  .Chatter__footer {
    background: #f7f5f2;
    flex: 0 1 auto;
    overflow: hidden;
    position: relative; }

.ChatterPost:not(:last-child) {
  border-top: 0.0625rem solid #d8d8d8; }

.ChatterPost__content {
  padding-left: 50px;
  position: relative; }

.ChatterPost .Avatar--small {
  position: absolute;
  top: .3em;
  left: 0; }

.ChatterPost__info {
  line-height: 1; }

.ChatterPost__user {
  color: #168095;
  margin-right: .6em; }
  @media (min-width: 0) {
    .ChatterPost__user {
      font-size: 12px;
      line-height: 16px; } }
  @media (min-width: 950px) {
    .ChatterPost__user {
      font-size: 14px;
      line-height: 18px; } }

.ChatterPost__date {
  color: #6d6d6e;
  font-weight: 300; }
  @media (min-width: 0) {
    .ChatterPost__date {
      font-size: 12px;
      line-height: 16px; } }
  @media (min-width: 950px) {
    .ChatterPost__date {
      font-size: 14px;
      line-height: 18px; } }

.ChatterPost__message {
  color: #3c3c3d; }
  @media (min-width: 0) {
    .ChatterPost__message {
      font-size: 16px;
      line-height: 24px; } }
  @media (min-width: 950px) {
    .ChatterPost__message {
      font-size: 18px;
      line-height: 24px; } }

.ChatterPost__link {
  color: #d84d4f;
  cursor: pointer; }
  @media (min-width: 0) {
    .ChatterPost__link {
      font-size: 12px;
      line-height: 16px; } }
  @media (min-width: 950px) {
    .ChatterPost__link {
      font-size: 14px;
      line-height: 18px; } }

.ChatterPost__flagged {
  color: #9e9e9e;
  font-weight: 300; }
  @media (min-width: 0) {
    .ChatterPost__flagged {
      font-size: 12px;
      line-height: 16px; } }
  @media (min-width: 950px) {
    .ChatterPost__flagged {
      font-size: 14px;
      line-height: 18px; } }

.ChatterPost__sending {
  color: #6d6d6e; }
  @media (min-width: 0) {
    .ChatterPost__sending {
      font-size: 12px;
      line-height: 16px; } }
  @media (min-width: 950px) {
    .ChatterPost__sending {
      font-size: 14px;
      line-height: 18px; } }

.Divider {
  border-bottom: 0.125rem solid #168095;
  height: 0;
  margin: 0;
  width: 100%; }
  .Divider :not(:first-child) {
    margin-top: 1.5rem; }
  .Divider :not(:last-child) {
    margin-bottom: 1.5rem; }

.EmptyState {
  align-items: center;
  border-top: 0.0625rem solid #d8d8d8;
  border-bottom: 0.0625rem solid #d8d8d8;
  color: #9e9e9e;
  display: flex;
  justify-content: center;
  min-height: 10em;
  padding: 1rem;
  text-align: center;
  width: 100%; }
  .EmptyState:not(:first-child) {
    margin-top: 1.8rem; }
  .EmptyState:not(:last-child) {
    margin-bottom: 1.8rem; }
  .EmptyState__title {
    font-weight: 300; }
    @media (min-width: 0) {
      .EmptyState__title {
        font-size: 21px;
        line-height: 28px; } }
    @media (min-width: 950px) {
      .EmptyState__title {
        font-size: 24px;
        line-height: 32px; } }

.Field:not(:first-child) {
  margin-top: 1rem; }

.Field:not(:last-child) {
  margin-bottom: 1rem; }

.Field__label {
  display: block;
  font-weight: 400;
  margin-bottom: 0.4rem; }
  @media (min-width: 0) {
    .Field__label {
      font-size: 21px;
      line-height: 28px; } }
  @media (min-width: 950px) {
    .Field__label {
      font-size: 24px;
      line-height: 32px; } }
  .Field__label.is-required::after {
    content: ' (required)';
    text-transform: uppercase;
    color: #9e9e9e; }
    @media (min-width: 0) {
      .Field__label.is-required::after {
        font-size: 12px;
        line-height: 16px; } }
    @media (min-width: 950px) {
      .Field__label.is-required::after {
        font-size: 14px;
        line-height: 18px; } }

.Field__error {
  color: #d84d4f; }
  @media (min-width: 0) {
    .Field__error {
      font-size: 12px;
      line-height: 16px; } }
  @media (min-width: 950px) {
    .Field__error {
      font-size: 14px;
      line-height: 18px; } }

.FileField {
  display: block; }
  .FileField input {
    position: absolute;
    top: -999in;
    left: -999in;
    width: 0;
    height: 0; }

.FileField__label {
  color: #d84d4f; }
  @media (min-width: 0) {
    .FileField__label {
      font-size: 12px;
      line-height: 16px; } }
  @media (min-width: 950px) {
    .FileField__label {
      font-size: 14px;
      line-height: 18px; } }

.BocFooter:not(x) {
  padding-bottom: 3rem;
  background: #8bc0ca;
  color: #50a0b0;
  border-top: 3rem solid #fff; }
  @media (min-width: 0) {
    .BocFooter:not(x) {
      font-size: 16px;
      line-height: 24px; } }
  @media (min-width: 950px) {
    .BocFooter:not(x) {
      font-size: 18px;
      line-height: 24px; } }
  @media (min-width: 950px) {
    .BocFooter:not(x) {
      padding-bottom: 5rem; } }

.BocFooter__section-title {
  color: #168095;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: .8em; }

.BocFooter__link {
  display: block;
  color: #50a0b0;
  margin: .5em 0; }
  .BocFooter__link:hover {
    color: #168095; }

.BocFooter__line {
  display: block;
  color: #50a0b0;
  margin: .5em 0; }
  .BocFooter__line a:hover {
    color: #168095; }

.BocFooter__social-link {
  display: inline-block;
  fill: #50a0b0;
  margin: .2em 0; }
  .BocFooter__social-link + .BocFooter__social-link {
    margin-left: .4em; }
  .BocFooter__social-link:hover {
    fill: #168095; }

.BocSocialIcon {
  display: inline-block;
  width: 2em;
  height: 2em; }

@media (max-width: 649px) {
  .BocFooter__legal {
    text-align: center; }
    .BocFooter__legal > * {
      display: inline-block;
      margin: 0 1rem 0 0; } }

.GameCard--public, .GameCard--private {
  border-radius: 0.15rem;
  border: 1px solid #9e9e9e;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .GameCard--public > *, .GameCard--private > * {
    background: #fff;
    padding: 1rem;
    flex: 0 0 100%; }
    .GameCard--public > *:first-child, .GameCard--private > *:first-child {
      border-radius: 0.0875rem 0.0875rem 0 0; }
    .GameCard--public > *:last-child, .GameCard--private > *:last-child {
      border-radius: 0 0 0.0875rem 0.0875rem; }
  .GameCard--public .Avatar--medium, .GameCard--private .Avatar--medium, .GameCard--public .GameIcon--medium, .GameCard--private .GameIcon--medium {
    flex: 0 0 auto; }

.GameCard__section {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  min-width: 0; }

.GameCard__section + .GameCard__section {
  border-top: 1px solid #9e9e9e; }

.GameCard__info {
  flex: 1 1 100%;
  line-height: 1.2;
  min-width: 0;
  padding-left: 0.5rem; }

.GameCard__league-name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #168095;
  font-weight: 600;
  margin: 0; }
  @media (min-width: 0) {
    .GameCard__league-name {
      font-size: 21px;
      line-height: 1; } }
  @media (min-width: 950px) {
    .GameCard__league-name {
      font-size: 24px;
      line-height: 1; } }

.GameCard__season-name, .GameCard__week, .GameCard__rank {
  display: inline-block;
  color: #6d6d6e;
  font-weight: 400; }
  @media (min-width: 0) {
    .GameCard__season-name, .GameCard__week, .GameCard__rank {
      font-size: 16px;
      line-height: 1; } }
  @media (min-width: 950px) {
    .GameCard__season-name, .GameCard__week, .GameCard__rank {
      font-size: 18px;
      line-height: 1; } }

.GameCard__week::before {
  color: #168095;
  font-weight: 600;
  content: '\00A0|\00A0'; }

.GameCard__avatar {
  flex: 0 0 auto;
  width: 4rem;
  height: 4rem; }

.GameCard__results {
  flex: 1 1 100%;
  line-height: 1.2;
  min-width: 0;
  padding-left: 0.5rem; }

.GameCard__earnings {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600; }
  @media (min-width: 0) {
    .GameCard__earnings {
      font-size: 21px;
      line-height: 28px; } }
  @media (min-width: 950px) {
    .GameCard__earnings {
      font-size: 24px;
      line-height: 32px; } }

.GameCard__rank {
  color: #9e9e9e; }

.GameCard__notification--week, .GameCard__notification,
.GameCard__notification--season {
  align-items: center;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0.7rem;
  padding-right: 3.8rem;
  padding-top: 0.7rem;
  position: relative; }
  @media (min-width: 0) {
    .GameCard__notification--week, .GameCard__notification,
    .GameCard__notification--season {
      font-size: 16px;
      line-height: 1.2; } }
  @media (min-width: 950px) {
    .GameCard__notification--week, .GameCard__notification,
    .GameCard__notification--season {
      font-size: 18px;
      line-height: 1.2; } }
  [grid-el~='>500'] .GameCard__notification--week, [grid-el~='>500'] .GameCard__notification, [grid-el~='>500']
  .GameCard__notification--season {
    padding-right: 2.4rem; }

.GameCard__notification--week {
  background: #9e9e9e; }

.GameCard__notification,
.GameCard__notification--season {
  background: #d84d4f; }

.GameCard__notification-info-icon {
  height: 1rem;
  margin: -.2em .2em 0 0;
  width: 1rem;
  vertical-align: middle; }

.GameCard__notification-text {
  flex: 0 0 100%;
  font-weight: 400; }

.GameCard__notification-subtext {
  flex: 0 0 100%;
  font-weight: 300; }

.GameCard__notification-go-icon {
  height: 2.4rem;
  position: absolute;
  right: 1rem;
  top: calc(50% - 1.2rem);
  width: 2.4rem; }

.GameCard--private .GameCard__league-name {
  text-transform: none; }
  @media (min-width: 0) {
    .GameCard--private .GameCard__league-name {
      font-size: 21px;
      line-height: 28px; } }
  @media (min-width: 950px) {
    .GameCard--private .GameCard__league-name {
      font-size: 24px;
      line-height: 32px; } }

[grid-el=""] > [class*="GameCard"] {
  display: none; }

[grid-el~='>500'] [class*="GameCard"] .Avatar--medium {
  order: 2; }

[grid-el~='>500'] .GameCard__section {
  border-radius: 0.0875rem 0 0 0.0875rem;
  flex: 0 0 50%; }

[grid-el~='>500'] .GameCard__section + .GameCard__section {
  border-top: 0;
  border-radius: 0 0.0875rem 0.0875rem 0;
  text-align: right; }
  [grid-el~='>500'] .GameCard__section + .GameCard__section .GameCard__results {
    padding-left: 0;
    padding-right: 0.5rem; }

[grid-el~='>500'] .GameCard__notification--week,
[grid-el~='>500'] .GameCard__notification--season {
  padding-right: 2.4rem; }

[grid-el~='>500'] .GameCard__notification-text {
  flex: 1 1 auto; }

[grid-el~='>500'] .GameCard__notification-subtext {
  flex: 1 1 auto;
  padding-left: 1rem;
  text-align: right; }

[grid-el~='>500'] .GameCard__notification-go-icon {
  height: 1rem;
  top: calc(50% - 0.5rem);
  width: 1rem; }

.GameHelp__header {
  text-align: center; }

.GameHelp__title {
  color: #168095;
  text-align: center; }
  @media (min-width: 0) {
    .GameHelp__title {
      font-size: 24px;
      line-height: 32px; } }
  @media (min-width: 950px) {
    .GameHelp__title {
      font-size: 36px;
      line-height: 42px; } }

.GameHelp__info {
  background: #e8f2f4;
  border-radius: .25rem;
  padding: 1rem;
  text-align: center; }
  @media (min-width: 650px) {
    .GameHelp__info {
      text-align: left; }
      .GameHelp__info .Button--large-primary {
        width: 100%; } }

.GameHelp__features {
  color: #168095;
  text-align: left; }
  @media (min-width: 0) {
    .GameHelp__features {
      font-size: 21px;
      line-height: 28px; } }
  @media (min-width: 950px) {
    .GameHelp__features {
      font-size: 24px;
      line-height: 32px; } }

.GameHelp__feature {
  padding-left: 1.5em; }
  .GameHelp__feature::before {
    content: '\2713';
    float: left;
    width: 1.5em;
    height: 1em;
    margin-left: -1.5em; }
  .GameHelp__feature:not(:first-child) {
    margin-top: .5em; }

.GameIcon--small:not(img), .GameIcon--medium:not(img), .GameIcon--large:not(img) {
  background: #fff; }

.GamePromo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #168095;
  border-radius: 0.15rem;
  text-align: center;
  background: #fff;
  padding: 1.5rem 1rem; }

.GamePromo__title {
  font-weight: 600;
  color: #168095;
  margin-top: .5rem; }
  @media (min-width: 0) {
    .GamePromo__title {
      font-size: 24px;
      line-height: 32px; } }
  @media (min-width: 950px) {
    .GamePromo__title {
      font-size: 36px;
      line-height: 42px; } }

.GamePromo__icon {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  margin: 1rem;
  background: #d0e6ea; }

.GamePromo__features {
  color: #3c3c3d;
  flex: 100 0 auto;
  font-weight: 300;
  margin: 0 0 1em; }

.GraphicBanner, .GraphicBanner--large {
  align-items: center;
  background-color: #168095;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%; }

.GraphicBanner__image {
  background-position: center;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.GraphicBanner__content {
  flex: 0 1 auto;
  max-width: 1024px;
  padding-left: calc(5rem - (5rem - 3.41333vh - 3.41333vw));
  padding-right: calc(5rem - (5rem - 3.41333vh - 3.41333vw));
  padding-top: calc(3rem - (3rem - 3.41333vh - 3.41333vw));
  padding-bottom: calc(3rem - (3rem - 3.41333vh - 3.41333vw));
  position: relative;
  text-align: center; }
  @media (min-width: 1024px) {
    .GraphicBanner__content {
      padding-left: 5rem; } }
  @media (min-width: 1024px) {
    .GraphicBanner__content {
      padding-right: 5rem; } }
  @media (min-width: 1024px) {
    .GraphicBanner__content {
      padding-top: 3rem; } }
  @media (min-width: 1024px) {
    .GraphicBanner__content {
      padding-bottom: 3rem; } }
  .GraphicBanner__content .Link,
  .GraphicBanner__content .Link:hover {
    color: #fc3; }

.GraphicBanner__subtitle {
  margin-bottom: calc(2rem - (2rem - 2.56vh - 2.56vw));
  color: #fff;
  font-weight: 300;
  line-height: 1.2em; }
  @media (min-width: 0) {
    .GraphicBanner__subtitle {
      font-size: 36px;
      line-height: 42px; } }
  @media (min-width: 950px) {
    .GraphicBanner__subtitle {
      font-size: 48px;
      line-height: 58px; } }
  @media (min-width: 1024px) {
    .GraphicBanner__subtitle {
      margin-bottom: 2rem; } }

.GraphicBanner__text {
  color: #fff; }
  .GraphicBanner__text:not(:first-child) {
    margin-top: .8em; }
  .GraphicBanner__text:not(:last-child) {
    margin-bottom: .8em; }

.GraphicBanner--large {
  min-height: 30rem;
  height: 70vw;
  max-height: 60vh; }
  @media (max-width: 1023px) {
    .GraphicBanner--large {
      height: auto;
      min-height: 0;
      max-height: none; } }

.Icon--small, .Icon--small-right, .Icon--small-left, .Icon, .Icon--right, .Icon--left, .Icon--alert {
  display: inline-block;
  fill: currentColor;
  transform: scale(1.4);
  vertical-align: baseline; }

.Icon, .Icon--alert {
  height: 1em;
  margin-bottom: -.15em;
  width: 1em; }

.Icon--small, .Icon--small-right, .Icon--small-left {
  height: .7em;
  width: .7em;
  margin-bottom: 0; }

.Icon--right {
  margin-left: 0.25em; }

.Icon--left {
  margin-right: 0.25em; }

.Icon--alert {
  color: #d84d4f; }

.Icon--small-right {
  margin-left: 0.25em; }

.Icon--small-left {
  margin-right: 0.25em; }

.BocAbbrev {
  fill: currentColor;
  height: 20px;
  width: 58px; }

.ImageField input[type='file'] {
  position: absolute;
  left: -9999in; }

.ImageField__thumbnail {
  margin-right: 0.4rem; }

.Logotype--home {
  width: 100%;
  max-width: 590px; }

#movie-container {
  background-color: #FFFFFF;
  padding-bottom: 1.666rem;
  padding-right: 1.666rem;
  margin-top: 20rem; }

#MoviePageHeroImage {
  background-size: cover;
  background-color: #FFF;
  height: 50rem;
  width: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  background-repeat: none;
  background-position: center top; }

#MoviePageHeroImageFade {
  bottom: 0;
  top: 50%;
  position: absolute;
  width: 100%;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
  /* FF3.6-15 */
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
  /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  /* IE6-9 */ }

#MoviePage_MovieTitle {
  font-size: 3rem;
  font-weight: 200;
  color: #168095;
  line-height: 3.625rem;
  margin-bottom: 1.25rem; }

#MoviePage_TotalEarnings {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.5rem; }

#MoviePage_TotalEarningsNumber {
  font-size: 3rem;
  font-weight: 200;
  line-height: 3.625rem;
  margin-bottom: 1.875rem; }

#movie-container .MoviePopup__summary-items {
  margin-bottom: 1.125rem; }

#MoviePage_CastCrew {
  padding: 1.125rem; }

#MoviePage_CastCrew .Text--small {
  margin-bottom: 1.125rem; }

#MoviePage_Poster img {
  width: 100%; }

.MovieCard, .MovieCard--cheap-seat, .MovieCard--date-alert {
  border-radius: 3px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap; }

.MovieCard__pick-image, .MovieCard__pick-message,
.MovieCard__pick-link {
  background-color: inherit;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.MovieCard .MovieCard__cap {
  background: #168095;
  color: #fff; }

.MovieCard .MovieCard__release-date,
.MovieCard .MovieCard__earnings {
  background-color: #d0e6ea;
  color: #168095; }

.MovieCard .MovieCard__secondary-result,
.MovieCard .MovieCard__remove-movie {
  background-color: #e8f2f4; }

.MovieCard .MovieCard__secondary-result {
  color: #168095; }

.MovieCard .MovieCard__pick {
  background-color: #e8f2f4; }

.MovieCard .MovieCard__pick-message {
  color: #168095; }

.MovieCard .MovieCard__history {
  background-color: #d0e6ea;
  color: #fff; }

.MovieCard .MovieCard__history-numbers {
  background-color: #ececec;
  color: #168095; }

.MovieCard .MovieCard__averages {
  color: #168095; }

.MovieCard.is-incomplete .MovieCard__cap {
  background: #d84d4f;
  color: #fff; }

.MovieCard.is-incomplete .MovieCard__release-date,
.MovieCard.is-incomplete .MovieCard__earnings {
  background-color: #d84d4f;
  color: #fff; }

.MovieCard.is-hidden .MovieCard__release-date,
.MovieCard.is-hidden .MovieCard__earnings {
  background-color: #e8f2f4;
  color: #168095; }

.MovieCard.is-final .MovieCard__release-date,
.MovieCard.is-final .MovieCard__earnings, .MovieCard.is-void .MovieCard__release-date,
.MovieCard.is-void .MovieCard__earnings {
  background-color: #168095;
  color: #fff; }

.MovieCard.is-empty {
  cursor: pointer; }
  .MovieCard.is-empty .MovieCard__cap {
    background: #d0e6ea;
    color: #fff; }

.MovieCard--cheap-seat .MovieCard__cap {
  background: #89857e;
  color: #fff; }

.MovieCard--cheap-seat .MovieCard__release-date,
.MovieCard--cheap-seat .MovieCard__earnings {
  background-color: #ebe6df;
  color: #89857e; }

.MovieCard--cheap-seat .MovieCard__secondary-result,
.MovieCard--cheap-seat .MovieCard__remove-movie {
  background-color: #f7f5f2; }

.MovieCard--cheap-seat .MovieCard__secondary-result {
  color: #89857e; }

.MovieCard--cheap-seat .MovieCard__pick {
  background-color: #f7f5f2; }

.MovieCard--cheap-seat .MovieCard__pick-message {
  color: #89857e; }

.MovieCard--cheap-seat .MovieCard__history {
  background-color: #f7f5f2;
  color: #fff; }

.MovieCard--cheap-seat .MovieCard__history-numbers {
  background-color: #fbfaf9;
  color: #89857e; }

.MovieCard--cheap-seat .MovieCard__averages {
  color: #89857e; }

.MovieCard--cheap-seat .MovieCard__pick-image::before {
  color: #89857e; }

.MovieCard--cheap-seat.is-incomplete .MovieCard__cap {
  background: #d84d4f;
  color: #fff; }

.MovieCard--cheap-seat.is-incomplete .MovieCard__release-date,
.MovieCard--cheap-seat.is-incomplete .MovieCard__earnings {
  background-color: #d84d4f;
  color: #fff; }

.MovieCard--cheap-seat.is-hidden .MovieCard__release-date,
.MovieCard--cheap-seat.is-hidden .MovieCard__earnings {
  background-color: #f7f5f2;
  color: #89857e; }

.MovieCard--cheap-seat.is-final .MovieCard__release-date,
.MovieCard--cheap-seat.is-final .MovieCard__earnings, .MovieCard--cheap-seat.is-void .MovieCard__release-date,
.MovieCard--cheap-seat.is-void .MovieCard__earnings {
  background-color: #89857e;
  color: #fff; }

.MovieCard--cheap-seat.is-empty {
  cursor: pointer; }
  .MovieCard--cheap-seat.is-empty .MovieCard__cap {
    background: #d6cdbf;
    color: #fff; }

.MovieCard--date-alert {
  cursor: pointer; }
  .MovieCard--date-alert .MovieCard__cap {
    background: #fc3;
    color: #fff; }
  .MovieCard--date-alert .MovieCard__release-date,
  .MovieCard--date-alert .MovieCard__earnings {
    background-color: #fc3;
    color: #89857e; }
  .MovieCard--date-alert .MovieCard__secondary-result,
  .MovieCard--date-alert .MovieCard__remove-movie {
    background-color: #ffe699; }
  .MovieCard--date-alert .MovieCard__secondary-result {
    color: #89857e; }
  .MovieCard--date-alert .MovieCard__pick {
    background-color: #fc3; }
  .MovieCard--date-alert .MovieCard__pick-message {
    color: #89857e; }
  .MovieCard--date-alert .MovieCard__history {
    background-color: #ffe699;
    color: #fff; }
  .MovieCard--date-alert .MovieCard__history-numbers {
    background-color: #ececec;
    color: #d6cdbf; }
  .MovieCard--date-alert .MovieCard__averages {
    color: #d6cdbf; }

.MovieCard__cap {
  border-radius: 3px 3px 0 0;
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  position: relative; }
  .MovieCard__cap .Icon {
    height: 20px;
    margin-bottom: 0;
    width: 20px; }

.MovieCard__select {
  border-radius: 3px 3px 0 0;
  display: block;
  padding: .5em;
  width: 100%; }

.MovieCard__rank-actual {
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  right: 9px;
  position: absolute; }

.MovieCard__cap-mobile {
  background-position: 50% calc(50% - 1.375rem);
  background-size: cover;
  padding: 37.5% 0 0; }
  .MovieCard__cap-mobile > * {
    background-color: #fff;
    color: #168095;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.MovieCard__pick {
  position: relative;
  display: block;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding-top: 150%;
  height: 0;
  overflow: hidden; }

.MovieCard__pick-image {
  display: block; }
  .MovieCard__pick-image::before, .MovieCard__pick-image::after {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    white-space: normal; }
  .MovieCard__pick-image::before {
    background-color: #3c3c3d;
    color: #168095;
    content: attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt);
    display: block;
    filter: blur(0.05em);
    font-size: 4vw;
    left: -20%;
    letter-spacing: .2em;
    line-height: .9;
    transform: scale(1.2) rotate(-30deg);
    text-transform: uppercase;
    width: 140%;
    word-break: break-all; }
    @media (min-width: 950px) {
      .MovieCard__pick-image::before {
        font-size: 40px; } }
  .MovieCard__pick-image::after {
    background: linear-gradient(to bottom right, rgba(22, 128, 149, 0.2) -5%, #3c3c3d 105%);
    color: #ececec;
    content: attr(alt);
    font-family: "Source Sans Pro", sans-serif;
    font-size: 24px;
    font-weight: 700;
    left: 0;
    line-height: 24px;
    padding: .5em;
    right: 0; }

.MovieCard__pick-message,
.MovieCard__pick-link {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 300;
  justify-content: center;
  line-height: 18px;
  padding: 18px;
  white-space: normal; }

.MovieCard__pick-link {
  color: #d84d4f; }

.MovieCard__weekend-num {
  background-color: #168095;
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  text-transform: uppercase;
  overflow: hidden; }

.MovieCard__enter-prediction {
  appearance: none;
  background: #fff;
  border: 2px solid #168095;
  color: #168095;
  font-size: 24px;
  font-weight: 600;
  height: 64px;
  line-height: 1.5rem;
  padding: 20px 0;
  text-align: center;
  width: 100%; }
  .MovieCard__enter-prediction::placeholder {
    color: #d84d4f;
    font-size: 18px;
    font-weight: 100; }

.MovieCard__release-date,
.MovieCard__earnings {
  overflow: hidden;
  line-height: 64px; }

.MovieCard__release-date {
  font-weight: 300;
  font-size: 18px; }

.MovieCard__release-date--select {
  display: block;
  font-size: 18px;
  font-weight: 300;
  margin-top: .2em; }

.MovieCard__release-day {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase; }

.MovieCard__week-count,
.MovieCard__week-count--negative {
  color: #168095;
  display: block;
  font-size: 14px;
  text-transform: uppercase; }

.MovieCard__week-count--negative {
  color: #d8af35; }

.MovieCard__earnings {
  font-weight: 600;
  font-size: 24px; }

.MovieCard__earnings-value {
  position: relative; }
  .is-void .MovieCard__earnings-value {
    color: rgba(255, 255, 255, 0.5); }
    .is-void .MovieCard__earnings-value::after {
      background: white;
      content: '';
      display: block;
      height: 2px;
      left: -2px;
      position: absolute;
      right: -2px;
      top: 50%; }

.MovieCard__secondary-result,
.MovieCard__remove-movie {
  font-size: 18px;
  font-weight: 300;
  line-height: 36px;
  border-top: 1px solid #fff; }

.MovieCard__secondary-result {
  background: #e8f2f4;
  border-radius: 3px;
  color: #168095; }

.MovieCard__remove-movie {
  color: #d84d4f; }

.MovieCard__secondary-result-no-data {
  font-size: 14px; }

.MovieCard__bonus {
  margin: 0.5rem 0; }

.MovieCard__history-numbers {
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  border-top: 1px solid #fff; }

.MovieCard__history {
  font-weight: 600;
  text-transform: uppercase; }

.MovieCard__history-date {
  float: left;
  margin-left: .625rem;
  width: 25%; }

.MovieCard__history-change {
  float: left;
  font-weight: 100;
  text-align: right;
  width: 25%; }

.MovieCard__history-earnings {
  float: right;
  margin-right: .625rem; }

.MovieCard__averages {
  border-top: 1px solid #fff; }

.MovieCard__topfive--average {
  background-color: #d0e6ea;
  border-radius: 3px 3px 0 0;
  font-weight: 600;
  margin-top: 1.25rem; }

.MovieCard__topfive--average,
.MovieCard__topfive--high,
.MovieCard__topfive--low,
.MovieCard__topfive--median {
  font-size: 14px;
  height: 36px;
  line-height: 36px; }

.MovieCard__topfive--high,
.MovieCard__topfive--low,
.MovieCard__topfive--median {
  background-color: #fbfaf9;
  font-weight: 300; }

.MovieCard__topfive-label {
  float: left;
  margin-left: .625rem; }

.MovieCard__topfive-figure {
  float: right;
  margin-right: .625rem; }

.MovieRow, .MovieRow--cheap-seat, .MovieRow--date-alert {
  align-items: stretch;
  cursor: default;
  display: flex;
  flex-direction: row;
  line-height: 1.1;
  margin-bottom: 1px;
  min-height: 3.625rem;
  width: 100%; }

.MovieRow {
  background-color: #168095;
  color: #168095; }
  .MovieRow .MovieRow__cap {
    color: #fff; }
  .MovieRow .MovieCard__enter-prediction {
    border-color: #168095; }
  .MovieRow .MovieRow__pick,
  .MovieRow .MovieRow__icon {
    background-color: #e8f2f4; }
  .MovieRow.is-empty {
    background-color: #d0e6ea;
    cursor: pointer; }
    .MovieRow.is-empty .MovieRow__cap {
      color: #fff; }
    .MovieRow.is-empty .MovieCard__enter-prediction {
      border-color: #d0e6ea; }
  .MovieRow.is-incomplete {
    background-color: #d84d4f;
    color: #fff; }
    .MovieRow.is-incomplete .MovieRow__cap {
      color: #fff; }
    .MovieRow.is-incomplete .MovieCard__enter-prediction {
      border-color: #d84d4f; }
    .MovieRow.is-incomplete .MovieRow__pick,
    .MovieRow.is-incomplete .MovieRow__icon {
      background-color: #d84d4f; }
  .MovieRow.is-editable {
    cursor: pointer; }
    .MovieRow.is-editable .MovieRow__pick {
      background-color: #fff;
      border-bottom: 1px solid #168095;
      border-top: 1px solid #168095; }
      .MovieRow.is-editable .MovieRow__pick:last-child {
        border-right: 1px solid #168095; }
    .MovieRow.is-editable .MovieRow__icon {
      background-color: #fff;
      border: 1px solid #168095;
      border-left: 0; }
  .MovieRow.is-locked {
    color: #168095; }
    .MovieRow.is-locked .MovieRow__pick,
    .MovieRow.is-locked .MovieRow__icon {
      background-color: #d0e6ea; }
  .MovieRow.is-final, .MovieRow.is-void {
    color: #fff; }
    .MovieRow.is-final .MovieRow__pick,
    .MovieRow.is-final .MovieRow__icon, .MovieRow.is-void .MovieRow__pick,
    .MovieRow.is-void .MovieRow__icon {
      background-color: #168095; }

.MovieRow--cheap-seat {
  background-color: #89857e;
  color: #89857e; }
  .MovieRow--cheap-seat .MovieRow__cap {
    color: #fff; }
  .MovieRow--cheap-seat .MovieCard__enter-prediction {
    border-color: #89857e; }
  .MovieRow--cheap-seat .MovieRow__pick,
  .MovieRow--cheap-seat .MovieRow__icon {
    background-color: #f7f5f2; }
  .MovieRow--cheap-seat.is-empty {
    background-color: #d6cdbf;
    cursor: pointer; }
    .MovieRow--cheap-seat.is-empty .MovieRow__cap {
      color: #fff; }
    .MovieRow--cheap-seat.is-empty .MovieCard__enter-prediction {
      border-color: #d6cdbf; }
  .MovieRow--cheap-seat.is-incomplete {
    background-color: #d84d4f;
    color: #fff; }
    .MovieRow--cheap-seat.is-incomplete .MovieRow__cap {
      color: #fff; }
    .MovieRow--cheap-seat.is-incomplete .MovieCard__enter-prediction {
      border-color: #d84d4f; }
    .MovieRow--cheap-seat.is-incomplete .MovieRow__pick,
    .MovieRow--cheap-seat.is-incomplete .MovieRow__icon {
      background-color: #d84d4f; }
  .MovieRow--cheap-seat.is-editable {
    cursor: pointer; }
    .MovieRow--cheap-seat.is-editable .MovieRow__pick {
      background-color: #fff;
      border-bottom: 1px solid #89857e;
      border-top: 1px solid #89857e; }
      .MovieRow--cheap-seat.is-editable .MovieRow__pick:last-child {
        border-right: 1px solid #89857e; }
    .MovieRow--cheap-seat.is-editable .MovieRow__icon {
      background-color: #fff;
      border: 1px solid #89857e;
      border-left: 0; }
  .MovieRow--cheap-seat.is-locked {
    color: #89857e; }
    .MovieRow--cheap-seat.is-locked .MovieRow__pick,
    .MovieRow--cheap-seat.is-locked .MovieRow__icon {
      background-color: #ebe6df; }
  .MovieRow--cheap-seat.is-final, .MovieRow--cheap-seat.is-void {
    color: #fff; }
    .MovieRow--cheap-seat.is-final .MovieRow__pick,
    .MovieRow--cheap-seat.is-final .MovieRow__icon, .MovieRow--cheap-seat.is-void .MovieRow__pick,
    .MovieRow--cheap-seat.is-void .MovieRow__icon {
      background-color: #89857e; }
  .MovieRow--cheap-seat .MovieRow__pick-message {
    color: #89857e; }

.MovieRow--date-alert {
  background-color: #fc3;
  color: #89857e; }
  .MovieRow--date-alert .MovieRow__cap {
    color: #fff; }
  .MovieRow--date-alert .MovieCard__enter-prediction {
    border-color: #fc3; }
  .MovieRow--date-alert .MovieRow__pick,
  .MovieRow--date-alert .MovieRow__icon {
    background-color: #ffe699; }

.MovieRow__cap {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 3.625rem; }
  .MovieRow__cap .Icon {
    width: 100%;
    height: 1.25em;
    margin-bottom: 0; }

.MovieRow__rank {
  font-size: 1.75rem;
  line-height: .9; }

.MovieRow__rank-actual {
  font-size: .75rem; }

.MovieRow__pick {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 300;
  flex: 1 1 100%;
  padding: .25rem .625rem;
  position: relative;
  min-width: 0; }
  .MovieRow__pick:not(:last-child) {
    padding-right: 0; }

.MovieRow__pick-link {
  color: #d84d4f;
  text-align: center; }

.MovieRow__pick-message {
  color: #168095;
  text-align: center; }

.MovieRow__movie-title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase; }

.MovieRow__release {
  white-space: nowrap;
  overflow: hidden;
  font-size: 1rem; }
  .MovieRow__release span {
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase; }

.MovieRow__earnings {
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.5rem;
  font-weight: 300; }

.MovieRow__earnings-value {
  position: relative; }
  .is-void .MovieRow__earnings-value {
    color: rgba(255, 255, 255, 0.5); }
    .is-void .MovieRow__earnings-value::after {
      background: white;
      content: '';
      display: block;
      height: 2px;
      left: -3px;
      position: absolute;
      right: -3px;
      top: 50%; }

.MovieRow__icon {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0 .625rem 0 .5rem;
  position: relative; }
  .MovieRow__icon::before {
    content: '';
    background: inherit;
    position: absolute;
    top: 0;
    left: -1px;
    width: 2px;
    height: 100%; }

.MoviePopup {
  -webkit-overflow-scrolling: touch;
  background: white;
  max-height: 100%;
  max-width: 650px;
  overflow: auto;
  padding: 0.75rem;
  position: relative;
  text-align: center;
  width: 100%; }
  @media (max-width: 649px) {
    .MoviePopup {
      height: 100%;
      padding-bottom: 100px; } }
  @media (min-width: 650px) {
    .MoviePopup {
      box-shadow: 0 0 10px rgba(60, 60, 61, 0.25); } }
  .MoviePopup__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%; }
    @media (min-width: 650px) {
      .MoviePopup__content {
        align-items: stretch; } }
    .MoviePopup__content > * {
      flex: 0 0 100%;
      position: relative;
      padding: 0.75rem; }
  .MoviePopup__overlay {
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100; }
  @media (min-width: 650px) {
    .MoviePopup__tags {
      flex-basis: auto;
      order: -1; } }
  @media (max-width: 649px) {
    .MoviePopup__actions {
      background: #ececec;
      bottom: 0;
      display: flex;
      left: 0;
      padding: 0;
      position: fixed;
      text-align: center;
      width: 100%; } }
  @media (min-width: 650px) {
    .MoviePopup__actions {
      flex-basis: auto;
      justify-content: flex-end;
      order: -1;
      position: static; } }
  @media (max-width: 649px) {
    .MoviePopup__action {
      flex: 1 1 50%;
      font-size: 18px;
      outline: none;
      padding: 1.125rem 1.5rem;
      text-transform: uppercase;
      white-space: nowrap;
      color: #d84d4f; } }
  @media (min-width: 650px) {
    .MoviePopup__action {
      display: block;
      height: calc(1em + 1.5rem);
      overflow: hidden;
      position: absolute;
      right: 0;
      text-indent: -999in;
      top: 0;
      width: calc(1em + 1.5rem); }
      .MoviePopup__action::after {
        align-items: center;
        bottom: 0;
        content: '✕';
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        text-indent: 0;
        top: 0; } }
  @media (max-width: 649px) {
    .MoviePopup__action--primary {
      flex: 1 1 50%;
      font-size: 18px;
      outline: none;
      padding: 1.125rem 1.5rem;
      text-transform: uppercase;
      white-space: nowrap;
      background: #d84d4f;
      color: white; } }
  @media (min-width: 650px) {
    .MoviePopup__action--primary {
      display: inline-block;
      padding: 1em;
      border-radius: .2em;
      background-color: #d84d4f;
      transition: background-color 300ms;
      color: #fff;
      cursor: pointer;
      text-align: center;
      vertical-align: middle;
      appearance: none;
      padding: 0.5rem 1em; } }
  @media (min-width: 650px) and (min-width: 0) {
    .MoviePopup__action--primary {
      font-size: 16px;
      line-height: 1; } }
  @media (min-width: 650px) and (min-width: 950px) {
    .MoviePopup__action--primary {
      font-size: 18px;
      line-height: 1; } }
  @media (min-width: 650px) {
      .MoviePopup__action--primary:hover {
        background-color: #b94a4b; } }
  @media (min-width: 650px) and (min-width: 0) {
    .MoviePopup__action--primary {
      font-size: 12px;
      line-height: 16px; } }
  @media (min-width: 650px) and (min-width: 950px) {
    .MoviePopup__action--primary {
      font-size: 14px;
      line-height: 18px; } }
  .MoviePopup__title {
    margin: 0; }
  .MoviePopup__trailer {
    position: relative; }
    @media (max-width: 649px) {
      .MoviePopup__trailer {
        order: -2;
        margin: -0.75rem -0.75rem 1.5rem;
        padding: 56.25% 0 0 !important;
        flex-basis: calc(100% + 1.5rem); } }
    @media (min-width: 650px) {
      .MoviePopup__trailer {
        flex-basis: 66.666%;
        padding-right: 0; }
        .MoviePopup__trailer + .MoviePopup__summary {
          flex-basis: 33.333%;
          padding-left: 1px; }
          .MoviePopup__trailer + .MoviePopup__summary .MoviePopup__summary-items {
            flex-wrap: wrap; } }
    .MoviePopup__trailer-video {
      background: #ececec;
      height: 100%; }
      @media (max-width: 649px) {
        .MoviePopup__trailer-video {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0; } }
    .MoviePopup__trailer-image {
      background: #ececec;
      background-size: cover;
      height: 100%;
      width: 100%;
      padding: inherit;
      position: absolute;
      top: 0;
      left: 0; }
      @media (min-width: 650px) {
        .MoviePopup__trailer-image {
          background-clip: content-box; } }
  .MoviePopup__summary {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch; }
    .MoviePopup__summary-poster {
      flex: 0 0 auto; }
      .MoviePopup__summary-poster img {
        display: block;
        width: 120px; }
        @media (min-width: 650px) {
          .MoviePopup__summary-poster img {
            display: none; } }
    .MoviePopup__summary-items {
      flex: 1 1 100%;
      display: flex;
      align-items: stretch; }
      @media (max-width: 649px) {
        .MoviePopup__summary-items {
          flex-wrap: wrap; } }
    .MoviePopup__summary-item {
      background: #e8f2f4;
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      justify-content: center;
      line-height: 1;
      padding: 0.5rem; }
      .MoviePopup__summary-item:not(:first-child) {
        margin-top: 1px; }
  @media (min-width: 650px) {
    .MoviePopup__overview {
      flex-basis: 66.666%; } }
  .MoviePopup__credits {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0; }
    @media (min-width: 650px) {
      .MoviePopup__credits {
        flex-basis: 33.333%; } }
  .MoviePopup__cast, .MoviePopup__crew {
    flex: 0 0 50%;
    padding: 0.75rem; }
    @media (min-width: 650px) {
      .MoviePopup__cast, .MoviePopup__crew {
        flex: 0 0 100%; } }
  .MoviePopup__edit-title {
    font-weight: 600;
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
    text-align: center; }
  .MoviePopup__edit-form {
    background-clip: content-box;
    display: block; }
  .MoviePopup .MovieCard__earnings {
    background: #d0e6ea;
    border-radius: 3px;
    color: #168095; }

.MoviePoster {
  position: relative;
  display: block;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding-top: 150%;
  height: 0;
  overflow: hidden;
  width: 100%; }

.MoviePoster__image {
  background-color: inherit;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  display: block; }
  .MoviePoster__image::before, .MoviePoster__image::after {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    white-space: normal; }
  .MoviePoster__image::before {
    background-color: #3c3c3d;
    color: #168095;
    content: attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt)attr(alt);
    display: block;
    filter: blur(0.05em);
    font-size: 4vw;
    left: -20%;
    letter-spacing: .2em;
    line-height: .9;
    transform: scale(1.2) rotate(-30deg);
    text-transform: uppercase;
    width: 140%;
    word-break: break-all; }
    @media (min-width: 950px) {
      .MoviePoster__image::before {
        font-size: 1.2rem; } }
  .MoviePoster__image::after {
    background: linear-gradient(to bottom right, rgba(22, 128, 149, 0.2) -5%, #3c3c3d 105%);
    color: #ececec;
    content: attr(alt);
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    left: 0;
    line-height: 1rem;
    padding: .5em;
    right: 0; }

.MovieStats {
  color: #89857e;
  display: table;
  text-align: center;
  white-space: nowrap;
  width: 100%; }
  @media (min-width: 0) {
    .MovieStats {
      font-size: 12px;
      line-height: 2; } }
  @media (min-width: 950px) {
    .MovieStats {
      font-size: 14px;
      line-height: 2; } }
  .MovieStats:first-child {
    margin-top: 10px; }
  .MovieStats__label {
    background-color: #e0dacf;
    border-radius: 3px 3px 0 0;
    display: table-caption;
    font-weight: 600;
    padding: 0 8px; }
  .MovieStats__stats {
    display: table-row;
    table-layout: fixed;
    width: 100%; }
    .MovieStats__stats:first-child > *:first-child {
      border-radius: 3px 0 0; }
    .MovieStats__stats:first-child > *:last-child {
      border-radius: 0 3px 0 0; }
    .MovieStats__stats:last-child > *:first-child {
      border-radius: 0 0 0 3px; }
    .MovieStats__stats:last-child > *:last-child {
      border-radius: 0 0 3px; }
    .MovieStats__stats > * {
      background-color: #fbfaf9;
      border-top: 1px solid #fff;
      display: table-cell;
      padding: 0 8px 0 0; }
    .MovieStats__stats > *:first-child {
      padding-left: 8px; }
    .MovieStats__stats > *:not(:only-child) {
      text-align: left; }
    .MovieStats__stats > *:first-child:not(:only-child) {
      text-align: left;
      width: 1px; }
    .MovieStats__stats > *:last-child:not(:only-child) {
      text-align: right; }
  @media (min-width: 950px) {
    .MovieStats__stat--small {
      width: 2.5em; } }
  .MovieStats__stat--alternate {
    font-weight: 600; }
  .MovieStats__stat--secondary {
    font-weight: 300; }

.BocNav {
  align-items: center;
  background: #168095;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 0 8px;
  text-transform: uppercase;
  top: 0;
  width: 100%; }

.BocNav__logo {
  color: #fff;
  display: block;
  flex: 0 0 auto;
  margin: 0 8px; }
  .BocNav__logo .BocAbbrev {
    display: block; }
    @media (max-width: 649px) {
      .BocNav__logo .BocAbbrev {
        height: 40px;
        width: 32px; } }
    @media (min-width: 650px) {
      .BocNav__logo .BocAbbrev {
        height: 50px; } }

.BocNav__links {
  display: flex;
  flex: 0 0 auto; }
  @media (min-width: 0) {
    .BocNav__links {
      font-size: 14px;
      line-height: 40px; } }
  @media (min-width: 950px) {
    .BocNav__links {
      font-size: 16px;
      line-height: 40px; } }

.BocNav__secondary-links {
  display: flex;
  flex: 10 0 auto;
  justify-content: flex-end;
  align-items: center;
  font-weight: 300;
  padding: 0 8px; }
  @media (min-width: 0) {
    .BocNav__secondary-links {
      font-size: 16px;
      line-height: 40px; } }
  @media (min-width: 950px) {
    .BocNav__secondary-links {
      font-size: 18px;
      line-height: 40px; } }

.BocNav__link {
  color: #8bc0ca;
  padding: 0 8px;
  position: relative; }
  .BocNav__link.is-selected {
    color: #fff; }
    .BocNav__link.is-selected::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff; }
  @media (min-width: 650px) {
    .BocNav__link {
      line-height: 50px; } }

.BocNav__login {
  color: #fc3;
  padding: 0 8px; }
  @media (min-width: 0) {
    .BocNav__login {
      font-size: 14px;
      line-height: 40px; } }
  @media (min-width: 950px) {
    .BocNav__login {
      font-size: 16px;
      line-height: 40px; } }

.BocNav__avatar {
  height: 32px;
  width: 32px;
  margin-right: 8px; }
  .BocNav__avatar.is-active {
    outline: 8px solid #d0e6ea;
    box-shadow: 0 0 0 8px #d0e6ea; }
  @media (min-width: 650px) {
    .BocNav__avatar {
      height: 42px;
      width: 42px; } }

.BocNav__more {
  color: #8bc0ca;
  padding: 0 8px; }

.SubNav {
  align-items: baseline;
  border-bottom: 1px solid #d84d4f;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding-bottom: .05px;
  padding-top: .05px;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 10; }
  .SubNav + * {
    margin-top: 40px; }
    @media (min-width: 650px) {
      .SubNav + * {
        margin-top: 49.6px; } }
  @media (min-width: 650px) {
    .SubNav {
      height: 49.6px; } }

.SubNav__title {
  color: #d84d4f;
  flex: 0 1 auto;
  font-size: 24px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media (min-width: 0) {
    .SubNav__title {
      font-size: 21px;
      line-height: 40px; } }
  @media (min-width: 950px) {
    .SubNav__title {
      font-size: 24px;
      line-height: 40px; } }
  @media (min-width: 650px) {
    .SubNav__title {
      line-height: 49.6px; } }

.SubNav__select {
  flex: 1 1 auto;
  padding: 0 1em 0 .2em; }
  .SubNav__select select {
    appearance: none;
    background-image: url("/img/svg-sprite/chevron-down.svg");
    background-position: right 50%;
    background-repeat: no-repeat;
    background-size: 1em;
    border: 0;
    color: #6d6d6e;
    cursor: pointer;
    font-weight: 300;
    max-width: 50vw;
    outline: none;
    padding: 0 1.2em 0 .5em;
    text-transform: uppercase; }
    @media (min-width: 0) {
      .SubNav__select select {
        font-size: 16px;
        line-height: 1; } }
    @media (min-width: 950px) {
      .SubNav__select select {
        font-size: 18px;
        line-height: 1; } }

.SubNav__more {
  flex: 0 0 auto;
  margin: -.5em -.5em -.5em 0;
  padding: .5em; }
  .SubNav__more .Icon {
    color: #6d6d6e;
    position: relative;
    top: -.1em; }

.SubNav__links {
  color: #9e9e9e;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  justify-content: flex-end; }

.SubNav__link {
  flex: 0 0 auto;
  padding: 0 .5em; }
  .SubNav__link.is-active {
    color: #d84d4f; }

.BocNavMenu--main,
.BocNavMenu--user, .BocNavMenu--sub {
  border-bottom: 1px solid #168095;
  display: none;
  left: 0;
  padding: 8px 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 11; }
  @media (min-width: 0) {
    .BocNavMenu--main,
    .BocNavMenu--user, .BocNavMenu--sub {
      font-size: 16px;
      line-height: 40px; } }
  @media (min-width: 950px) {
    .BocNavMenu--main,
    .BocNavMenu--user, .BocNavMenu--sub {
      font-size: 18px;
      line-height: 40px; } }
  .is-active.BocNavMenu--main,
  .is-active.BocNavMenu--user, .is-active.BocNavMenu--sub {
    display: block; }
  @media (min-width: 650px) {
    .BocNavMenu--main,
    .BocNavMenu--user, .BocNavMenu--sub {
      line-height: 1.4;
      top: 40px; } }

.BocNavMenu__header {
  align-items: center;
  display: flex;
  top: -8px;
  padding: 0 16px;
  position: relative; }

.BocNavMenu__title {
  flex: 1 1 auto; }

.BocNavMenu__close {
  color: inherit;
  flex: 0 0 auto;
  opacity: .5; }

.BocNavMenu__link {
  color: inherit;
  display: block;
  padding: 4px 16px; }

.BocNavMenu--main,
.BocNavMenu--user {
  background: #d0e6ea;
  border-bottom-color: #168095;
  color: #168095;
  position: fixed; }
  .BocNavMenu--main .BocNavMenu__header,
  .BocNavMenu--user .BocNavMenu__header {
    background: #168095;
    color: #fff; }

.BocNavMenu--sub {
  background: #fff;
  border-bottom-color: #d84d4f;
  color: #6d6d6e;
  position: fixed; }
  .BocNavMenu--sub .BocNavMenu__header {
    background: #fff;
    border-bottom: 1px solid #ececec;
    color: #d84d4f; }

@media (min-width: 650px) {
  .BocNavMenu--user {
    width: auto;
    left: auto;
    right: 0;
    top: 50px; }
  .BocNavMenu__header {
    display: none; } }

@keyframes BOC-OVERLAY-IN {
  0% {
    height: 0;
    opacity: 0; }
  1% {
    height: 100%;
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes BOC-OVERLAY-OUT {
  0% {
    height: 100%;
    opacity: 1; }
  99% {
    height: 100%;
    opacity: 0; }
  100% {
    height: 0;
    opacity: 0; } }

.BocNavMenu__overlay {
  content: '';
  background: rgba(30, 114, 131, 0.7);
  height: 0;
  left: 0;
  position: fixed;
  top: 40px;
  width: 100%;
  z-index: 9; }
  @media (min-width: 650px) {
    .BocNavMenu__overlay {
      background: transparent; } }
  .BocNavMenu__overlay.is-not-active {
    animation: BOC-OVERLAY-OUT 300ms; }
  .BocNavMenu__overlay.is-active {
    height: calc(100vh - 40px);
    animation: BOC-OVERLAY-IN 300ms; }

.Notification--primary, .Notification--general, .Notification--alert, .Notification--success {
  border-radius: 0.25rem;
  padding: 0.5rem;
  position: relative;
  text-align: center; }
  @media (min-width: 0) {
    .Notification--primary, .Notification--general, .Notification--alert, .Notification--success {
      font-size: 16px;
      line-height: 24px; } }
  @media (min-width: 950px) {
    .Notification--primary, .Notification--general, .Notification--alert, .Notification--success {
      font-size: 18px;
      line-height: 24px; } }
  .has-close.Notification--primary, .has-close.Notification--general, .has-close.Notification--alert, .has-close.Notification--success {
    padding-left: calc(2.25rem + 1em);
    padding-right: calc(2.25rem + 1em); }
  @media (min-width: 650px) {
    .Notification--primary:not(.Notification--small), .Notification--general:not(.Notification--small), .Notification--alert:not(.Notification--small), .Notification--success:not(.Notification--small) {
      padding: 1.125rem; } }

.Notification--primary {
  background-color: #89857e;
  color: #fff; }

.Notification--general {
  background-color: #ebe6df;
  color: #89857e; }

.Notification--alert {
  background-color: #ffe699;
  color: #3c3c3d; }

.Notification--success {
  background-color: #37b958;
  color: #fff; }

.Notification__close {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5rem; }
  @media (min-width: 650px) {
    .Notification__close:not(.Notification--small) {
      padding: 1.125rem; } }

@media (min-width: 0) {
  .Notification--small {
    font-size: 12px;
    line-height: 16px; } }

@media (min-width: 950px) {
  .Notification--small {
    font-size: 14px;
    line-height: 18px; } }

.Overlay {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100; }

.Overlay__header {
  background: #168095;
  color: #e8f2f4;
  flex: 0 0 auto;
  padding-bottom: 16px;
  padding-top: 16px; }
  @media (min-width: 650px) {
    .Overlay__header {
      padding-bottom: 30px;
      padding-top: 30px; } }

.Overlay__title {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: .9em;
  margin: 0; }

.Overlay__content {
  -webkit-overflow-scrolling: touch;
  overflow: auto; }

.Overlay__close .Icon {
  width: 1em;
  height: 1em; }

.Panel--general {
  border-radius: 0.15rem; }

.Panel--general {
  background-color: #f7f5f2;
  color: #3c3c3d; }
  .Panel--general > * {
    padding: 1rem; }
    .Panel--general > * + * {
      border-top: solid 0.0625rem #d6cdbf; }

.Panel__body {
  text-align: left; }

.Panel__header {
  text-align: center; }

.Panel__message {
  text-align: center; }

@media (min-width: 950px) {
  .is-hidden\@desktop {
    display: none !important; } }

@media (max-width: 949px) {
  .is-visible\@desktop {
    display: none !important; } }

@media (min-width: 650px) {
  .is-hidden\@tablet {
    display: none !important; } }

@media (max-width: 649px) {
  .is-visible\@tablet {
    display: none !important; } }

.TextSelect--primary {
  appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-size: 1em;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0 1.3em 0 0; }

.TextSelect--primary {
  background-image: url('data:image/svg+xml;utf8,<svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill="#168095" d="M8,12.2 L14,6.152 L12.8571429,5 L8,9.896 L3.14285714,5 L2,6.152 L8,12.2 L8,12.2 Z"></path></svg>'); }

.Spinner {
  color: #ececec;
  display: block;
  height: 60px;
  margin: 25vh auto;
  width: 60px; }

.Summary {
  flex-direction: column;
  align-items: stretch;
  background: #168095;
  border-radius: 0.15rem;
  display: flex;
  overflow: hidden;
  white-space: nowrap; }
  @media (min-width: 0) {
    .Summary {
      font-size: 21px;
      line-height: 28px; } }
  @media (min-width: 950px) {
    .Summary {
      font-size: 24px;
      line-height: 32px; } }
  @media (min-width: 650px) {
    .Summary {
      flex-direction: row; } }

.Summary__item--primary, .Summary__item--primary-right, .Summary__item--secondary, .Summary__item--alternate {
  align-items: flex-start;
  color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  min-width: 0;
  padding: 0.35rem 0.5rem; }
  @media (min-width: 650px) {
    .Summary__item--primary, .Summary__item--primary-right, .Summary__item--secondary, .Summary__item--alternate {
      padding: 0.7rem 1rem; } }

@media (min-width: 650px) {
  .Summary__item--primary-right {
    align-items: flex-end; } }

.Summary__item--secondary {
  opacity: .5; }

.Summary__item--alternate {
  background: #d0e6ea;
  color: #168095; }

.Summary__item-label {
  font-weight: 400;
  text-transform: uppercase;
  opacity: .7; }
  @media (min-width: 0) {
    .Summary__item-label {
      font-size: 16px;
      line-height: 24px; } }
  @media (min-width: 950px) {
    .Summary__item-label {
      font-size: 18px;
      line-height: 24px; } }

.Summary__item-data {
  font-weight: 600; }
  @media (min-width: 0) {
    .Summary__item-data {
      font-size: 21px;
      line-height: 28px; } }
  @media (min-width: 950px) {
    .Summary__item-data {
      font-size: 28px;
      line-height: 34px; } }

.Summary__item-data--large {
  font-weight: 600; }
  @media (min-width: 0) {
    .Summary__item-data--large {
      font-size: 24px;
      line-height: 32px; } }
  @media (min-width: 950px) {
    .Summary__item-data--large {
      font-size: 36px;
      line-height: 42px; } }

.Summary__item-info {
  font-weight: 300;
  opacity: .6; }
  @media (min-width: 0) {
    .Summary__item-info {
      font-size: 21px;
      line-height: 28px; } }
  @media (min-width: 950px) {
    .Summary__item-info {
      font-size: 28px;
      line-height: 34px; } }

.DataTable {
  border-spacing: 0;
  line-height: 21px;
  text-align: left;
  width: 100%; }
  .DataTable > thead > tr > * {
    background: #8bc0ca;
    color: #fff;
    text-transform: uppercase;
    padding: 0.6rem 1rem; }
    @media (min-width: 0) {
      .DataTable > thead > tr > * {
        font-size: 12px;
        line-height: 16px; } }
    @media (min-width: 950px) {
      .DataTable > thead > tr > * {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 650px) {
      .DataTable > thead > tr > *[sort-by] {
        cursor: pointer; }
      .DataTable > thead > tr > *.is-active {
        background: #168095; }
      .DataTable > thead > tr > *.is-numeric {
        text-align: right; } }
  .DataTable > thead > tr:first-child > :first-child {
    border-top-left-radius: 0.15rem; }
  .DataTable > thead > tr:first-child > :last-child {
    border-top-right-radius: 0.15rem; }
  .DataTable > tbody > tr > * {
    border-bottom: 0.0625rem solid #d8d8d8;
    color: #168095;
    padding: 0.6rem 1rem; }
    .DataTable > tbody > tr > *.is-active {
      font-weight: 600; }
    @media (min-width: 650px) {
      .DataTable > tbody > tr > *.is-numeric {
        text-align: right;
        white-space: nowrap; }
      .DataTable > tbody > tr > *.DataTable__primary, .DataTable > tbody > tr > *.DataTable__secondary {
        width: 30%; } }
  .DataTable > tbody > tr:hover > * {
    background-color: #e8f2f4; }
  @media (max-width: 649px) {
    .DataTable, .DataTable > *, .DataTable > * > tr, .DataTable > * > tr > * {
      display: block; }
    .DataTable > thead {
      height: 2em;
      position: relative; }
      .DataTable > thead > tr {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        justify-content: flex-start;
        z-index: 5; }
        .DataTable > thead > tr > .is-active {
          border-radius: 0.15rem 0.15rem 0 0;
          padding: 0.5rem;
          text-align: center;
          order: -1; }
          .DataTable > thead > tr > .is-active::after {
            content: url("/img/icons/chevron-down.svg");
            filter: invert();
            position: absolute;
            right: 10px;
            top: 8px;
            opacity: .3; }
          .DataTable > thead > tr > .is-active:hover::after {
            opacity: .7; }
        .DataTable > thead > tr > [sort-by]:not(.is-active) {
          background-color: #fff;
          color: #50a0b0;
          display: none;
          border-bottom: 1px solid #d8d8d8;
          border-radius: 0;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); }
        .DataTable > thead > tr.is-open > [sort-by]:not(.is-active) {
          display: block; }
        .DataTable > thead > tr.is-open > .is-active::after {
          content: url("/img/icons/chevron-up.svg");
          opacity: .7; }
    .DataTable > tfoot {
      display: none; }
    .DataTable > tbody > tr {
      border-bottom: 0.0625rem solid #d8d8d8;
      overflow: hidden;
      padding: 0;
      width: 100%; }
      .DataTable > tbody > tr > * {
        border-bottom: 0;
        padding: 0.5rem;
        position: relative; }
      .DataTable > tbody > tr > :not(.DataTable__primary):not(.DataTable__secondary) {
        float: left;
        line-height: 42px; }
      .DataTable > tbody > tr > .DataTable__primary {
        height: calc(0.5rem + 21px);
        overflow: hidden;
        font-size: inherit;
        padding-bottom: 0; }
      .DataTable > tbody > tr > .DataTable__secondary {
        height: calc(0.5rem + 21px);
        overflow: hidden;
        padding-top: 0; }
        .DataTable > tbody > tr > .DataTable__secondary ~ .DataTable__secondary {
          background-color: #fff;
          margin-top: calc(-.5rem - 1.4em);
          z-index: 2; }
          .DataTable > tbody > tr > .DataTable__secondary ~ .DataTable__secondary:not(.is-active) {
            display: none !important; }
      .DataTable > tbody > tr:hover > .DataTable__secondary ~ .DataTable__secondary {
        background-color: #e8f2f4; } }

.DataTable__primary {
  font-weight: 600; }
  @media (min-width: 0) {
    .DataTable__primary {
      font-size: 16px;
      line-height: 24px; } }
  @media (min-width: 950px) {
    .DataTable__primary {
      font-size: 18px;
      line-height: 24px; } }

.DataTable__avatar {
  margin: -0.5em -1rem -0.5em 0; }
  @media (max-width: 649px) {
    .DataTable__avatar {
      margin: -0.7em -0.5rem -0.3em 0; } }

.ResultTable {
  background: #f7f5f2;
  border-radius: 3px;
  border-spacing: 0;
  color: #89857e;
  font-weight: 300;
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: 100%; }
  @media (min-width: 0) {
    .ResultTable {
      font-size: 11px;
      line-height: 14px; } }
  @media (min-width: 950px) {
    .ResultTable {
      font-size: 12px;
      line-height: 16px; } }
  .ResultTable th {
    padding-right: 0.5rem;
    padding-bottom: 0.25rem;
    text-align: left; }
  .ResultTable td {
    padding-bottom: 0.25rem;
    text-align: right; }
  .ResultTable tfoot > tr > * {
    border-top: 0.0625rem solid #89857e;
    font-weight: 600;
    padding-top: 0.25rem;
    padding-bottom: 0; }

.ResultData, .ResultData--total, .ResultData--highlight {
  font-weight: 300;
  overflow: hidden; }
  @media (min-width: 0) {
    .ResultData, .ResultData--total, .ResultData--highlight {
      font-size: 12px;
      line-height: 16px; } }
  @media (min-width: 950px) {
    .ResultData, .ResultData--total, .ResultData--highlight {
      font-size: 14px;
      line-height: 18px; } }
  .ResultData:not(first-child), .ResultData--total:not(first-child), .ResultData--highlight:not(first-child) {
    margin-top: 0.5rem; }
  .ResultData:not(last-child), .ResultData--total:not(last-child), .ResultData--highlight:not(last-child) {
    margin-bottom: 0.5rem; }

.ResultData {
  color: #3c3c3d; }

.ResultData--total {
  border-top: 0.0625rem solid #168095;
  color: #168095;
  font-weight: 600;
  padding-top: 0.5rem; }

.ResultData--highlight {
  background-color: #e8f2f4;
  border-radius: 3px;
  color: #168095;
  padding: 0.5rem; }
  @media (min-width: 0) {
    .ResultData--highlight {
      font-size: 11px;
      line-height: 14px; } }
  @media (min-width: 950px) {
    .ResultData--highlight {
      font-size: 12px;
      line-height: 16px; } }

.ResultData__label {
  clear: both;
  float: left; }

.ResultData__value {
  float: right; }

.BocTabs__tab.is-active::before {
  border-bottom: 0.8em solid #168095;
  border-left: 0.8em solid transparent;
  border-right: 0.8em solid transparent;
  bottom: 0;
  content: '';
  left: 50%;
  margin-left: -0.8em;
  position: absolute; }

.BocTabs {
  border-bottom: 0.15em solid #168095;
  display: none;
  font-size: 1rem;
  overflow: hidden; }
  .BocTabs:not(:first-child) {
    margin-top: 1.5em; }
  .BocTabs:not(:last-child) {
    margin-bottom: 1.5em; }
  @media (min-width: 650px) {
    .BocTabs {
      display: block; } }

.BocTabs__tab {
  color: #d84d4f;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1;
  margin: 0 0.75em;
  padding-bottom: 1.25em;
  text-align: center;
  transition: color 300ms; }
  .BocTabs__tab:first-child {
    margin-left: 0; }
  .BocTabs__tab:last-child {
    margin-right: 0; }
  .BocTabs__tab:hover {
    color: #b94a4b; }
  .BocTabs__tab.is-active {
    color: #168095;
    position: relative; }

.BocTabs__header ~ .BocTabs__header {
  margin-top: 1.5rem;
  position: relative; }

@media (min-width: 650px) {
  .BocTabs__header {
    display: none; } }

@media (min-width: 650px) {
  .BocTabs__header:not(.is-active),
  .BocTabs__content:not(.is-active) {
    display: none; } }

.Tag, .Tag--primary {
  padding: 0.5rem 1rem;
  background: #ececec;
  float: left; }

.Tags {
  overflow: hidden; }
  @media (min-width: 0) {
    .Tags {
      font-size: 12px;
      line-height: 16px; } }
  @media (min-width: 950px) {
    .Tags {
      font-size: 14px;
      line-height: 18px; } }
  .Tags > *:first-child {
    border-radius: 5px 0 0 5px; }
  .Tags > *:last-child {
    border-radius: 0 5px 5px 0; }

.Tag--primary {
  color: white;
  text-transform: uppercase;
  background: #168095; }

.TeamHeader {
  align-items: center;
  color: #168095;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; }
  @media (min-width: 650px) {
    .TeamHeader {
      flex-direction: row; } }

.TeamHeader__avatar {
  flex: 0 0 auto; }

.TeamHeader__name {
  flex: 0 1 auto;
  font-weight: 600;
  margin: .2em 0 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  @media (min-width: 0) {
    .TeamHeader__name {
      font-size: 36px;
      line-height: 42px; } }
  @media (min-width: 950px) {
    .TeamHeader__name {
      font-size: 48px;
      line-height: 58px; } }
  @media (min-width: 650px) {
    .TeamHeader__name {
      margin: 0 0 0 .5em; } }
  @media (max-width: 649px) {
    .TeamHeader__name {
      font-size: 24px;
      line-height: 32px; } }

.TeamSummary {
  align-items: center;
  background: #168095;
  border-radius: 0.15rem;
  color: #fff;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: 100%; }
  @media (min-width: 0) {
    .TeamSummary {
      font-size: 21px;
      line-height: 28px; } }
  @media (min-width: 950px) {
    .TeamSummary {
      font-size: 24px;
      line-height: 32px; } }
  .TeamSummary > * {
    flex: 0 0 auto;
    min-width: 0;
    margin: 0.35rem 0.5rem; }
    @media (min-width: 650px) {
      .TeamSummary > * {
        margin: 0.7rem 1rem; } }

.TeamSummary__avatar {
  margin: 0.7rem 0 0.7rem 0.7rem; }
  @media (max-width: 649px) {
    .TeamSummary__avatar {
      display: none; } }

.TeamSummary__name {
  flex: 1 1 auto;
  font-weight: 600;
  margin-left: .5em;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; }
  @media (min-width: 0) {
    .TeamSummary__name {
      font-size: 21px;
      line-height: 1.2; } }
  @media (min-width: 950px) {
    .TeamSummary__name {
      font-size: 28px;
      line-height: 1.2; } }

.TeamSummary__info {
  text-align: right; }

.TeamSummary__info-primary {
  font-weight: 600; }
  @media (min-width: 0) {
    .TeamSummary__info-primary {
      font-size: 21px;
      line-height: 28px; } }
  @media (min-width: 950px) {
    .TeamSummary__info-primary {
      font-size: 28px;
      line-height: 34px; } }

.TeamSummary__info-secondary {
  font-weight: 300;
  opacity: .7; }
  @media (min-width: 0) {
    .TeamSummary__info-secondary {
      font-size: 16px;
      line-height: 24px; } }
  @media (min-width: 950px) {
    .TeamSummary__info-secondary {
      font-size: 18px;
      line-height: 24px; } }

.TextField, .TextField--icon input {
  display: block;
  background-color: #fff;
  border: 0.0625rem solid #9e9e9e;
  border-radius: 0.25rem;
  outline: 0;
  padding: .5rem .875rem;
  width: 100%; }
  @media (min-width: 0) {
    .TextField, .TextField--icon input {
      font-size: 16px;
      line-height: 1.25; } }
  @media (min-width: 950px) {
    .TextField, .TextField--icon input {
      font-size: 18px;
      line-height: 1.25; } }
  .TextField:focus, .TextField--icon input:focus {
    border-color: #168095; }
  .TextField::placeholder, .TextField--icon input::placeholder {
    color: #d8d8d8; }
  .has-error.TextField, .TextField--icon input.has-error, .ng-invalid.ng-touched.TextField, .TextField--icon input.ng-invalid.ng-touched {
    border-color: #d84d4f; }

.TextField--icon {
  display: block;
  position: relative; }
  @media (min-width: 0) {
    .TextField--icon {
      font-size: 16px;
      line-height: 1.25; } }
  @media (min-width: 950px) {
    .TextField--icon {
      font-size: 18px;
      line-height: 1.25; } }
  .TextField--icon input {
    padding-left: calc(1.2em + 1.5rem); }

.TextField__icon {
  border-bottom-left-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
  border-right: 0.0625rem solid #9e9e9e;
  color: #9e9e9e;
  height: calc(1.25em + 1rem);
  left: 0.0625rem;
  padding: .5rem;
  position: absolute;
  top: 0.0625rem;
  width: calc(1.25em + 1rem); }
  :focus + .TextField__icon {
    background-color: #168095;
    border-color: #168095;
    color: #fff; }
