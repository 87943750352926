// FONTS

$boc-base-font: 'Source Sans Pro', sans-serif;
$boc-headline-font: 'Changa', sans-serif;

// FONT WEIGHTS

$font-light: 300;
$font-normal: 400;
$font-bold: 600;

// FONT SIZES

$font-breakpoints: (
  phone: (
    width: 0,
    xxxxlarge: ( font-size: 48px, line-height: 58px),
    xxxlarge: ( font-size: 36px, line-height: 42px),
    xxlarge: ( font-size: 24px, line-height: 32px),
    xlarge: ( font-size: 21px, line-height: 28px),
    large: ( font-size: 21px, line-height: 28px),
    normal: ( font-size: 16px, line-height: 24px),
    nav: ( font-size: 14px, line-height: 16px),
    small: ( font-size: 12px, line-height: 16px),
    xsmall: ( font-size: 11px, line-height: 14px)
  ),
  desktop: (
    width: $desktop-width,
    xxxxlarge: ( font-size: 64px, line-height: 64px),
    xxxlarge: ( font-size: 48px, line-height: 58px),
    xxlarge: ( font-size: 36px, line-height: 42px),
    xlarge: ( font-size: 28px, line-height: 34px),
    large: ( font-size: 24px, line-height: 32px),
    normal: ( font-size: 18px, line-height: 24px),
    nav: ( font-size: 16px, line-height: 20px),
    small: ( font-size: 14px, line-height: 18px),
    xsmall: ( font-size: 12px, line-height: 16px)
  )
);

// SPACING

$boc-line-height: 1.4;
$boc-line-height-phone: 1.2;
$boc-paragraph-spacing: .7rem;

@mixin type-spacing($top, $bottom: $top) {
  &:not(:first-child) { margin-top: $top; }
  &:not(:last-child) { margin-bottom: $bottom; }
}

@mixin ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// BASE HEADER STYLES

@mixin boc-header--fancy {
  color: #fff;
  font-family: $boc-headline-font;
  letter-spacing: .05em;
  text-shadow: .08em .08em 0 rgba(0, 0, 0, .4);
  text-transform: uppercase;
}

@mixin boc-header--section {
  @include type-spacing($type-spacing--loose, $type-spacing);
  border-bottom: $boc-border solid $primary-color;
  color: $base-color--light;
  font-weight: $font-light;
  padding-bottom: $boc-paragraph-spacing;
  text-transform: uppercase;
}

@mixin boc-header--level1 {
  @include type-spacing($type-spacing--loose, $type-spacing);
  color: $primary-color;
  font-weight: $font-bold;
}

@mixin boc-header--level2 {
  @include type-spacing($type-spacing--loose, $type-spacing);
  color: $accent-color;
  font-weight: $font-bold;
}

@mixin boc-header--caption {
  font-weight: $font-bold;
  text-transform: uppercase;
}
