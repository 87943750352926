.ImageField {
  input[type='file'] {
    position: absolute;
    left: -9999in;
  }
}

.ImageField__thumbnail {
  margin-right: $type-spacing--tight;
}
