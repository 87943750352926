$boc-table-spacing--vert: .6rem;
$boc-table-spacing--horiz: 1rem;
$boc-table-spacing--collapsed: .5rem;
$boc-table--line-height: 21px;
$boc-table-border: $boc-border--thin solid $base-color--xxlight;

.DataTable {
  border-spacing: 0;
  line-height: $boc-table--line-height;
  text-align: left;
  width: 100%;

  // Tablet & Desktop

  > thead {
    > tr {
      > * {
        @include responsive-font(small);
        background: $primary-color--xlight;
        color: $page-color;
        text-transform: uppercase;
        padding: $boc-table-spacing--vert $boc-table-spacing--horiz;

        @include at-tablet-width {
          &[sort-by] {
            cursor: pointer;
          }

          &.is-active {
            background: $primary-color;
          }

          &.is-numeric {
            text-align: right;
          }
        }
      }

      &:first-child {
        > :first-child {
          border-top-left-radius: $panel-radius;
        }
        > :last-child {
          border-top-right-radius: $panel-radius;
        }
      }
    }
  }

  > tbody {
    > tr {
      > * {
        border-bottom: $boc-table-border;
        color: $primary-color;
        padding: $boc-table-spacing--vert $boc-table-spacing--horiz;

        &.is-active {
          font-weight: $font-bold;
        }

        @include at-tablet-width {

          &.is-numeric {
            text-align: right;
            white-space: nowrap;
          }

          &.DataTable__primary,
          &.DataTable__secondary {
            width: 30%;
          }
        }
      }

      &:hover > * {
        background-color: $primary-color--xxxlight;
      }

    }
  }

  // Phone (vertical layout)

  @include below-tablet-width {

    // set all table elements to block
    &, & > *, & > * > tr, & > * > tr > * { display: block; }

    > thead {
      height: 2em;
      position: relative;

      > tr {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        justify-content: flex-start;
        z-index: 5;

        > .is-active {
          border-radius: $panel-radius $panel-radius 0 0;
          padding: $boc-table-spacing--collapsed;
          text-align: center;
          order: -1;

          &::after {
            content: url('/img/icons/chevron-down.svg');
            filter: #{'invert()'};
            position: absolute;
            right: 10px;
            top: 8px;
            opacity: .3;
          }

          &:hover::after {
            opacity: .7;
          }
        }


        > [sort-by]:not(.is-active) {
          background-color: #fff;
          color: $primary-color--light;
          display: none;
          border-bottom: 1px solid $base-color--xxlight;
          border-radius: 0;
          box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
        }

        &.is-open {

          > [sort-by]:not(.is-active) {
            display: block;
          }

          > .is-active::after {
            content: url('/img/icons/chevron-up.svg');
            opacity: .7;
          }
        }
      }
    }

    > tfoot {
      display: none;
    }

    > tbody {
      > tr {
        border-bottom: $boc-table-border;
        overflow: hidden;
        padding: 0;
        width: 100%;

        // all cells
        > * {
          border-bottom: 0;
          padding: $boc-table-spacing--collapsed;
          position: relative;
        }

        // normal cells
        > :not(.DataTable__primary):not(.DataTable__secondary) {
          float: left;
          line-height: $boc-table--line-height * 2;
        }

        > .DataTable__primary {
          height: calc(#{$boc-table-spacing--collapsed} + #{$boc-table--line-height}); // limit to one line
          overflow: hidden;
          font-size: inherit;
          padding-bottom: 0;
        }

        > .DataTable__secondary {
          height: calc(#{$boc-table-spacing--collapsed} + #{$boc-table--line-height}); // limit to one line
          overflow: hidden;
          padding-top: 0;
          ~ .DataTable__secondary {
            background-color: #fff;
            margin-top: calc(-.5rem - 1.4em);
            z-index: 2;
            &:not(.is-active) { display: none !important; }
          }
        }

        &:hover {
          > .DataTable__secondary {
            ~ .DataTable__secondary {
              background-color: $primary-color--xxxlight;
            }
          }
        }
      }
    }
  }

}

.DataTable__primary {
  @include responsive-font(normal);
  font-weight: $font-bold;
}

.DataTable__avatar {
  @extend %Avatar--small;
  margin: (-.5em) (-$boc-table-spacing--horiz) (-.5em) 0;

  @include below-tablet-width {
    margin: (-.7em) (-$boc-table-spacing--collapsed) (-.3em) 0;
  }
}

.ResultTable {
  @include responsive-font(xsmall);
  background: $alternate-color--xxlight;
  border-radius: 3px;
  border-spacing: 0;
  color: $alternate-color--xdark;
  font-weight: $font-light;
  margin: $xtight-spacing 0;
  padding: $xtight-spacing;
  width: 100%;

  th {
    padding-right: $xtight-spacing;
    padding-bottom: $xxtight-spacing;
    text-align: left;
  }

  td {
    padding-bottom: $xxtight-spacing;
    text-align: right;
  }

  tfoot > tr > * {
    border-top: $boc-border--thin solid $alternate-color--xdark;
    font-weight: $font-bold;
    padding-top: $xxtight-spacing;
    padding-bottom: 0;
  }
}

%ResultData {
  @include responsive-font(small);
  font-weight: $font-light;
  overflow: hidden;

  &:not(first-child) {
    margin-top: $xtight-spacing;
  }

  &:not(last-child) {
    margin-bottom: $xtight-spacing;
  }
}

.ResultData {
  @extend %ResultData;
  color: $base-color;
}

.ResultData--total {
  @extend %ResultData;
  border-top: $boc-border--thin solid $primary-color;
  color: $primary-color;
  font-weight: $font-bold;
  padding-top: $xtight-spacing;
}

.ResultData--highlight {
  @extend %ResultData;
  @include responsive-font(xsmall);
  background-color: $primary-color--xxxlight;
  border-radius: 3px;
  color: $primary-color;
  padding: $xtight-spacing;
}

.ResultData__label {
  clear: both;
  float: left;
}

.ResultData__value {
  float: right;
}
