.Divider {
  border-bottom: $boc-border solid $primary-color;
  height: 0;
  margin: 0;
  width: 100%;

  :not(:first-child) {
    margin-top: $loose-spacing;
  }

  :not(:last-child) {
    margin-bottom: $loose-spacing;
  }
}
