// MEDIA MIXINS

@mixin at-tablet-width {
  @media (min-width: $tablet-width) {
    @content;
  }
}

@mixin at-desktop-width {
  @media (min-width: $desktop-width) {
    @content;
  }
}

@mixin at-page-width {
  @media (min-width: $page-width) {
    @content;
  }
}

@mixin below-tablet-width {
  @media (max-width: $tablet-width - 1) {
    @content;
  }
}

@mixin below-desktop-width {
  @media (max-width: $desktop-width - 1) {
    @content;
  }
}

@mixin below-page-width {
  @media (max-width: $page-width - 1) {
    @content;
  }
}

// ### responsive($prop, $size, $percent-change)
// Sets the prop to the given size at page width and above.
// Below page width, the property will scale down based on
// the screen size (width and height) and the $percent-change
// modifier provided, which defaults to 50.

@mixin responsive($prop, $size, $percent-change: 50) {
  // $size @ $page-width
  // $size * percentage change as screen size decreases
  $percent: $percent-change / 100;
  $one-unit: strip-unit($page-width) / 1000;
  $scaled-unit: $percent / $one-unit;
  #{$prop}: calc(#{$size} - (#{$size} - #{2 / $scaled-unit}vh - #{2 / $scaled-unit}vw));
  @media (min-width: $page-width) {
    #{$prop}: $size;
  }
}

// ### responsive-font($size, $line-height)
// Include font-size and line-height for the given size keyword at each breakpoint.
// $line-height defaults to the value in the config for the specified size. You can also
// pass in a custom value, or null to prevent line-height from being set.

// @include responsive-font(xlarge) --> 28px/34px
// @include responsive-font(xlarge, 1) --> 28px/1
// @include responsive-font(xlarge, null) --> 28px

@mixin responsive-font($size: normal, $line-height: $size) {
  $has-line-height: if($line-height == none, false, true);
  $custom-line-height: if($size == $line-height, false, true);
  @each $breakpoint, $breakpoint-styles in $font-breakpoints {
    $size-styles: map-get($breakpoint-styles, $size);
    @media (min-width: map-get($breakpoint-styles, width)) {
      font-size: map-get($size-styles, font-size);
      @if $has-line-height {
        line-height: if($custom-line-height, $line-height, map-get($size-styles, line-height));
      }
    }
  }
}

@function responsive-font-size($breakpoint, $size) {
  @return map-get(map-get(map-get($font-breakpoints, $breakpoint), $size), font-size);
}

@function responsive-line-height($breakpoint, $size) {
  @return map-get(map-get(map-get($font-breakpoints, $breakpoint), $size), line-height);
}
