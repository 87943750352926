%PageSection {
  background-color: $page-color;
  display: block;
  box-sizing: border-box;
  padding: $page-margin $page-margin-phone;
  position: relative;
  width: 100%;

  @include at-tablet-width {
    padding: $page-margin;
  }

  @include at-page-width {
    // 2000 == 976
    // 1000 - (1024 / 2 - 30)
    padding: $page-margin calc(50% - #{$page-width / 2 - $page-margin});
  }
}

[page-section],
.PageSection {
  @extend %PageSection;
}
