%Panel {
  border-radius: $panel-radius;
}

.Panel--general {
  @extend %Panel;

  background-color: $alternate-color--xxlight;
  color: $base-color;

  > * {
    padding: $panel-spacing;

    + * {
      border-top: solid $boc-border--thin $alternate-color;
    }
  }
}

.Panel__body {
  text-align: left;
}

.Panel__header {
  text-align: center;
}


.Panel__message {
  text-align: center;
}
