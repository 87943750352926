* {
  background: none;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  list-style: none;
  margin: 0;
  padding: 0;
}

svg:not([full-color]) {
  fill: currentColor;
}

html {
  background: $primary-color--xlight;
  color: $base-color;
  font-family: $boc-base-font;
  font-size: $base-small;
  font-weight: $font-normal;
  line-height: $boc-line-height-phone;

  &.has-no-nav {
    background: $page-color;
  }

  @include at-tablet-width {
    line-height: $boc-line-height;
  }
  @include at-desktop-width {
    font-size: $base-large;
  }
}

a {
  text-decoration: none;
}

b {
  font-weight: $font-bold;
}

i {
  font-style: italic;
}

button {
  font: inherit;
}

[boc-toggle] {
  cursor: pointer;
}
