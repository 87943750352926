#BlogPostHeroImage {
	background-size: cover;
	color: #fff;
	height: 500px;
	position: relative;
	width: 100%;
}

#BlogPostHeroShadow {
	bottom: 0;
	top: 50%;
	position: absolute;
	text-align: center;
	width: 100%;
	z-index: 1;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,.75) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,.75) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.75) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

#BlogPostHeroContent {
	bottom: 30px;
	position: absolute;
	text-align: center;
	z-index: 2;
	width: 100%;
}

#BlogPostHeadline {
	font-size: 64px;
	font-weight: 100;
	line-height: 64px;
}

#BlogPostType {
	color: #FFCC33;
	font-weight: 600;
	text-transform: uppercase;
}

.BlogPostByline_Avatar {
	background-color: #8BC0CA;
	border-radius: 100%;
	float: left;
	height: 60px;
	width: 60px;
}

.BlogPostByLine_Author {
	font-weight: 400;
	margin-left: 70px;
}


.BlogPostByLine_TimeStamp {
	font-weight: 200;	
	margin-left: 70px;
}

#BlogPostContent {
	font-size: 18px;
	line-height: 24px;
	font-weight: 200;
}

#BlogPostContent_Editorial em {
	font-style: italic;
}

#BlogPostContent_Editorial strong {
	font-weight: 600;
}

#BlogPostContent_WeekendTop10-Title {
	background-color: #8BC0CA;
	border-radius: 4px 4px 0 0;
	color: #FFFFFF;
	height: 50px;
	line-height: 50px;
	text-align: center;
	text-transform: uppercase;
}

.Top10-Listing {
	display: block;
	overflow: hidden;
	padding: 10px 0;
	border-bottom: solid #D8D8D9 1px;
}

.Top10_Numbers {
	float: left;
	width: 25%;
}

.Top10_WeekendNumber {
	color: #168095;
	font-size: 18px;
	font-weight: 400;
	line-height: 21px;
	text-align: right;
}

.Top10_TotalNumber {
}

.Top10_MovieTitle {
	float: right;
	width: 75%;
	font-size: 18px;
	font-weight: 400;
	line-height: 21px;
	padding-left: 10px;
}

/* Add styles for strong and em for blog posts */

.BlogListPost {
	max-width: 960px;
	margin-bottom: 30px;
	border-bottom: solid 5px #168095;
}

.BlogListPost .BlogPostByline {
	margin-bottom: 30px;
}

.BlogListPostImage {
	margin-bottom: 20px;
	height: 482px;
	display: block;
	background-position: center;
	background-size: cover;
}

.BlogListPostImage img {
	width: 100%;
}

.BlogListPostType {
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
	text-transform: uppercase;
}

.BlogListPostTitle {
	font-size: 48px;
	font-weight: 200;
	line-height: 58px;
	margin-bottom: 20px;
}

.BlogListPagination {
	text-align: center;
}

.BlogListPagination span {
    padding: 0 16px;
    display: inline-block;
}
.BlogListPaginationCurrent {
    background-color: #168095;
    color: white;
    border-radius: 100%;
    line-height: 40px;
    height: 40px;
}
