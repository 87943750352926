%TextField__input {
  @include responsive-font(normal, 1.25);
  display: block;
  background-color: $page-color;
  border: $field-border solid $base-color--xlight;
  border-radius: $field-radius;
  outline: 0;
  padding: .5rem .875rem;
  width: 100%;

  &:focus {
    border-color: $primary-color;
  }

  &::placeholder {
    color: $base-color--xxlight;
  }

  &.has-error,
  &.ng-invalid.ng-touched {
    border-color: $error-color;
  }

}

.TextField {
  @extend %TextField__input;
}


.TextField--icon {
  @include responsive-font(normal, 1.25);
  display: block;
  position: relative;

  input {
    @extend %TextField__input;
    padding-left: calc(1.2em + 1.5rem);
  }
}

.TextField__icon {
  border-bottom-left-radius: $field-radius - $field-border;
  border-top-left-radius: $field-radius - $field-border;
  border-right: $field-border solid $base-color--xlight;
  color: $base-color--xlight;
  height: calc(1.25em + 1rem);
  left: $field-border;
  padding: .5rem;
  position: absolute;
  top: $field-border;
  width: calc(1.25em + 1rem);

  :focus + & {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $page-color;
  }
}
