// HEADER STYLES

$title-spacing: 1.5rem;

%page-title,
.PageTitle {
  @include responsive-font(xxxlarge);
  color: $primary-color;
  font-weight: $font-light;
  margin-bottom: $title-spacing;

  &--alternate {
    color: $base-color;
  }
}

%page-title-large,
.PageTitle--large {
  @include responsive-font(xxxxlarge);
  color: $primary-color;
  font-weight: $font-light;
  margin-bottom: $title-spacing;

  &--alternate {
    color: $base-color;
  }
}

%page-title-small,
.PageTitle--small {
  @include responsive-font(xxlarge);
  color: $primary-color;
  font-weight: $font-light;
  margin-bottom: $title-spacing;
}

%page-subtitle,
.PageSubtitle {
  @include responsive-font(xxlarge);
  color: $base-color--xlight;
  font-weight: $font-light;
  margin-bottom: $title-spacing;

  .PageTitle + & {
    margin-top: -$title-spacing * .75;
  }
}

%SectionTitle,
.SectionTitle {
  @include responsive-font(xlarge);
  border-bottom: $boc-border solid $primary-color;
  color: $base-color;
  font-weight: $font-normal;
  padding-bottom: .4em;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-bottom: .9em;
  }
}

%Heading,
.Heading {
  @include responsive-font(large);
  color: $base-color;
  font-weight: $font-bold;
}

%Subheading {
  color: $primary-color;
  font-weight: $font-bold;
  text-transform: uppercase;
}

.Subheading {
  @extend %Subheading;
  @include responsive-font(normal);
}

.Subheading--small {
  @extend %Subheading;
  @include responsive-font(small);
}

// PARAGRAPHS

%Text,
.Text {
  @include responsive-font(normal);
  &:not(:last-child) { margin-bottom: 20px; }
}

.Text--large {
  @include responsive-font(large);
  font-weight: $font-light;
  &:not(:last-child) { margin-bottom: 20px; }
}

.Text--small {
  @include responsive-font(small);
  &:not(:last-child) { margin-bottom: 20px; }
}

.Text--xsmall {
  @include responsive-font(xsmall);
  &:not(:last-child) { margin-bottom: 20px; }
}

.HelpText {
  @include responsive-font(small);
  display: block;
  font-style: italic;
  font-weight: $font-light;
}

// LISTS

.List {
  @extend %Text;
  padding-left: 2em;

  > li {
    list-style: inherit;
    margin: .4em 0;
  }
}

ul.List {
  list-style: disc;
}

ol.List {
  list-style: decimal;
}

// LINKS

.Link {
  color: $accent-color;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $accent-color--dark;
    text-decoration: underline;
  }
}

// OTHER

.Example {
  @extend %Text;
  border-left: 4px solid $base-color--xxlight;
  padding-left: 1em;
}

.Highlight {
  color: $primary-color;
  font-style: normal;
}

.Warning {
  color: $accent-color;
}

// HELPERS

keep-together,
.KeepTogether {
  display: inline-block;
  vertical-align: baseline;
}
